import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const BtnMainMore: FC<Omit<IconProps, 'size'>> = (props) => {
  const { ...rest } = props;
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM19.2947 27.7089C18.9032 27.3194 18.9016 26.6862 19.2911 26.2947L24.5588 21H12V19H24.613L19.2947 13.7089C18.9032 13.3194 18.9016 12.6862 19.2911 12.2947C19.6806 11.9032 20.3138 11.9016 20.7053 12.2911L27.7053 19.2553L28.4142 19.9606L27.7089 20.6695L20.7089 27.7053C20.3194 28.0968 19.6862 28.0984 19.2947 27.7089Z"
        fill="white"
      />
    </svg>
  );
};

export default BtnMainMore;
