import { useHelper } from '@/common/hooks/useHelper';
import BtnOutlink from '@/common/icons/BtnOutClick';
import routeOutSide from '@/common/routeOutSide';
import Link from 'next/link';
import useStyles from './hooks/useStyles';

interface IMainTitle {
  title: string;
}
export default function MainTitle({ title }: IMainTitle) {
  const styles = useStyles();
  const { getTextWithLineBreaks } = useHelper();
  return (
    <>
      <span className={styles.title}>{getTextWithLineBreaks(title)}</span>
      <Link
        aria-label="link to INSHIDE NHN"
        href={routeOutSide.insideNhn}
        target="_blank"
        className={styles.link}
      >
        <BtnOutlink fill="black" className={styles.btnOutLink} />
      </Link>
    </>
  );
}
