/* eslint-disable react/no-danger */
import LinkButtonArrow from '@/common/icons/LinkButtonArrow';
import RightArrow2 from '@/common/icons/RightArrow2';
import { helper } from '@/common/utils';
import Link from 'next/link';
import { twJoin } from 'tailwind-merge';
import type { PostListProps, PostPreview } from '../../types';
import NewsSkeletonLayout from './NewsSkeleton';
import NewsTitle from './NewsTitle';

export default function PostList<T extends PostPreview>({
  type,
  title,
  newPostCount,
  posts,
  moreLinkText,
  moreLink,
  isLoading
}: PostListProps<T>) {
  const getPostLink = (post: PostPreview | string | any) => {
    if (post.link !== undefined) {
      return post.link.toString();
    }

    if (post.id !== undefined) {
      return `/pr/${post.id}`;
    }
    return '/';
  };

  const getLinkIcon = (link: string) =>
    helper.isOutLink(link) ? (
      <LinkButtonArrow className="relative top-1" fill="black" />
    ) : (
      <RightArrow2 className="h-24 w-12" />
    );

  const containerWidth = type === 'pr' ? 'w-404 min-w-404' : 'w-384 min-w-384';

  return (
    <article className={twJoin('news-section h-176 text-left notLastChild:mr-60', containerWidth)}>
      <NewsTitle title={title}>
        <span className="text-primary-selected">{newPostCount}</span>
      </NewsTitle>

      {/* skeleton 추가 */}
      <NewsSkeletonLayout type={type} on={isLoading}>
        <ul className="row mb-24 space-y-6 text-left">
          {posts?.map((post: T | any) => {
            return (
              <li
                className="column  space-y-6 text-body10-m text-grayscale-4 oneline-ellipsis"
                key={post.link ? post.link : post?.id}
              >
                {/* 각 구역의 내용 */}
                <Link
                  aria-label="move to detail post"
                  href={getPostLink(post)}
                  className="hover:underline"
                  target={helper.isOutLink(getPostLink(post)) ? '_blank' : '_self'}
                >
                  <span
                    className="text-body10-m"
                    dangerouslySetInnerHTML={{ __html: post.title }}
                  />
                </Link>
              </li>
            );
          })}
        </ul>
      </NewsSkeletonLayout>
      <Link
        href={moreLink}
        aria-label={moreLinkText}
        target={helper.isOutLink(moreLink) ? '_blank' : '_self'}
        className="more-link flex min-h-24 items-center space-x-7 text-body11-l  text-grayscale-1"
      >
        <span className="relative">{moreLinkText}</span>
        {getLinkIcon(moreLink)}
      </Link>
    </article>
  );
}
