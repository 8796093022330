import apiRoutes from '@/common/constants/api/route';
import { usePureApiQuery } from '@/common/hooks/usePureApiQuery ';
import { CareerResponse } from '@/models/main/careers/careers.model';

const useCareers = () => {
  const { data, isLoading, isError } = usePureApiQuery<CareerResponse>({
    key: ['careers'],
    method: 'get',
    datas: { params: { 'intensive-recruiting': 'Y', page: '0', size: '6' } },
    path: apiRoutes.OUT_CAREERS
  });

  return {
    isError,
    isLoading,
    data
  };
};

export default useCareers;
