import layout from '@/common/constants/layout';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { twJoin } from 'tailwind-merge';

interface IuseStyles {
  currentSliderIndex?: number;
}
export default function useStyles({ currentSliderIndex }: IuseStyles = {}) {
  const styles = {
    container: useDeviceClasses({
      common: 'relative',
      desktop: 'mx-auto h-800 w-full',
      mobile: 'h-600v'
    }),
    innerContainer: useDeviceClasses({
      common: 'pointer-events-none absolute z-20 mx-auto',
      mobile: 'top-179v w-full items-center px-20v',
      desktop: twJoin('bottom-200 left-0 right-0 transition-bottom ', layout.desktopLayout),
      ENdesktop:
        currentSliderIndex === 0
          ? 'bottom-256 desktopM:bottom-256'
          : 'bottom-184 desktopM:bottom-184'
    }),
    imageContainer: useDeviceClasses({
      desktop: twJoin('relative z-20', layout.desktopLayout),
      mobile: 'w-full'
    }),
    image: 'pointer-events-none  z-[1] object-cover'
  };
  return { ...styles };
}
