import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import RightArrow2 from '@/common/icons/RightArrow2';
import { helper } from '@/common/utils';
import useStockHelper from '@/components/specific/Ir/StockPriceInformation/hooks/useStockHelper';
import DesktopView from '@/components/unit/DesktopView';
import MobileView from '@/components/unit/MobileView';
import { useStockInformation } from '@/services/ir/stock/stock.service';
import Link from 'next/link';
import { twJoin } from 'tailwind-merge';
import NewsSkeletonLayout from './NewsSkeleton';
import NewsTitle from './NewsTitle';

interface StockInfoProps {
  title: string;
  moreText: string;
  moreLink: string;
}

export default function StockInfo({ title, moreLink, moreText }: StockInfoProps) {
  const { stockInformations, isLoading, isError } = useStockInformation();
  const { askPrice, comparedToPreviousPrice, fluctuationType, updateDateTime } = stockInformations;
  const { icon, textColor } = useStockHelper({
    fluctuationType
  });
  const noDataStyle = twJoin(
    useDeviceClasses({ desktop: 'relative h-176', mobile: 'w-full h-full' }),
    'w-full'
  );
  const isMobile = useIsMobile();

  if (isLoading && isMobile) return <ul className={noDataStyle} />;

  return (
    <>
      <DesktopView>
        <div className="relative h-full">
          <NewsTitle title={title} />
          <ul>
            <NewsSkeletonLayout type="stock" on={isLoading || isError}>
              <>
                <li className="flex items-center space-x-32">
                  <strong className="text-t6-l">
                    {askPrice ? helper.formatPrice(askPrice) : '-'}
                  </strong>
                  <div className="flex items-center space-x-4">
                    <span className={textColor}>{comparedToPreviousPrice || '-'}</span>
                    <span className="relative">{icon || '-'}</span>
                  </div>
                </li>
                <li className="text-left">
                  <em className="text-body11-m text-grayscale-4">
                    KRX {updateDateTime ? helper.dateFormatYearMonth(updateDateTime) : '-'}
                  </em>
                </li>
              </>
            </NewsSkeletonLayout>
            <li className="absolute bottom-0">
              <Link
                aria-label="move to more stock Info"
                href={moreLink}
                className="more-link flex min-h-24 items-center space-x-7 text-body11-l  text-grayscale-1"
              >
                <span className="relative">{moreText}</span>
                <RightArrow2 className="h-24 w-12" />
              </Link>
            </li>
          </ul>
        </div>
      </DesktopView>
      <MobileView>
        <div className="flex items-center space-x-12v">
          <span className="text-body9-l-v">{askPrice ? helper.formatPrice(askPrice) : '-'}</span>
          <div className="flex items-baseline space-x-4v">
            <span className={twJoin('text-body13-s-v', textColor)}>
              {comparedToPreviousPrice || '-'}
            </span>
            <span className="relative  h-8v w-8v" style={{ top: '-0.25vw' }}>
              {icon || '-'}
            </span>
          </div>
        </div>
      </MobileView>
    </>
  );
}
