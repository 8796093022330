import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const BtnOutlink: FC<Omit<IconProps, 'size'>> = (props) => {
  const { fill, isHover = true, ...rest } = props;
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle
        className={isHover ? 'hover:fill-primary-selected' : ''}
        cx="20"
        cy="20"
        r="20"
        fill={fill || '#212126'}
      />
      <path
        fill={fill === 'white' ? '#212126' : 'white'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.264 24.9527C24.2654 25.505 24.7143 25.9516 25.2665 25.9502C25.8188 25.9488 26.2654 25.4999 26.264 24.9476L26.2386 15.0228L26.2361 14.0228L25.2361 14.0254L15.3619 14.0507C14.8096 14.0521 14.3631 14.501 14.3645 15.0533C14.3659 15.6055 14.8148 16.0521 15.3671 16.0507L22.8681 16.0315L13.9503 24.9493L15.3645 26.3635L24.2449 17.4831L24.264 24.9527Z"
      />
    </svg>
  );
};

export default BtnOutlink;
