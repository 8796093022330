import SkeletonThemeBox from '@/components/unit/SkeletonThemeBox';
import React, { FC, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { twJoin } from 'tailwind-merge';

interface NewsSkeletonProps {
  type?: 'pr' | 'stock' | 'career';
  on: boolean;
  children: ReactNode;
}
interface SkeletonStyle {
  [key: string]: StyleProps;
}
interface StyleProps {
  line1: string;
  line2: string;
  line3: string;
}

const NewsSkeletonLayout: FC<NewsSkeletonProps> = ({ type = 'pr', on = false, children }) => {
  const getStyleWithDefaultStyle = (str: string) => twJoin('h-16 !rounded-4', str);

  const style: SkeletonStyle = {
    pr: {
      line1: getStyleWithDefaultStyle('!w-320'),
      line2: getStyleWithDefaultStyle('!w-240'),
      line3: getStyleWithDefaultStyle('!w-360 mb-31')
    },
    career: {
      line1: getStyleWithDefaultStyle('!w-240'),
      line2: getStyleWithDefaultStyle('!w-320'),
      line3: getStyleWithDefaultStyle('!w-300 mb-31')
    },
    stock: {
      line1: getStyleWithDefaultStyle('!w-108'),
      line2: getStyleWithDefaultStyle('!w-40'),
      line3: getStyleWithDefaultStyle('!w-108 mt-8')
    }
  };

  if (!on) return children;
  if (type === 'stock') {
    return (
      <SkeletonThemeBox wrapperBox className="space-y-16">
        <div className="flex h-74 w-180 flex-col items-start justify-start">
          <div className="flex h-48 items-center space-x-32">
            <Skeleton className={style[type].line1} containerClassName="flex-1" />
            <Skeleton className={style[type].line2} containerClassName="flex-1" />
          </div>
          <Skeleton className={style[type].line3} />
        </div>
      </SkeletonThemeBox>
    );
  }

  return (
    <div className="mb-26 h-90 w-360 leading-none">
      <SkeletonThemeBox wrapperBox className="space-y-16">
        <Skeleton className={style[type].line1} />
        <Skeleton className={style[type].line2} />
        <Skeleton className={style[type].line3} />
      </SkeletonThemeBox>
    </div>
  );
};

export default NewsSkeletonLayout;
