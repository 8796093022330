const IR_FINANCIALSTATEMENT = '/ir/financial-statement';
const IR_DISCLOSURES = '/ir/disclosures';
const IR_ARCHIVES = '/ir/archives';
const IR_SHAREHOLDERMEETINGS = '/ir/shareholder-meetings';
const IR_NOTICES = '/ir/notices'; // 공고상세는 /{id}
const IR_EVENTS = '/ir/events';
const IR_STOCKINFORMATION = '/ir/stock-information';
const IR_STOCKSTATUSES = '/ir/stock-statuses';
const IR_MEETINGPOSSIBLEDATES = '/ir/meeting-possible-dates';
const IR_MEETINGPOSSIBLETIMES = '/ir/meeting-possible-times';
const IR_MEETINGAGENCY_OPTIONS = '/ir/meeting-codes';
const IR_MEETINGS = '/ir/meetings'; //
const IR_YEARS = '/ir/years'; //
const OUT_CAREERS = '/careers/job-postings'; //
const PRIVACY = '/footer/policy-privacy-versions';
const PROPOSALS = '/footer/proposals';
const PRESS_RELEASES = '/pr/press-releases';
const BRAND_STORIES = '/pr/brand-stories';

const apiRoutes = {
  IR_FINANCIALSTATEMENT,
  IR_DISCLOSURES,
  IR_ARCHIVES,
  IR_STOCKSTATUSES,
  IR_SHAREHOLDERMEETINGS,
  IR_MEETINGAGENCY_OPTIONS,
  IR_STOCKINFORMATION,
  IR_NOTICES,
  IR_EVENTS,
  IR_MEETINGPOSSIBLEDATES,
  IR_MEETINGPOSSIBLETIMES,
  IR_MEETINGS,
  IR_YEARS,
  PRIVACY,
  PRESS_RELEASES,
  PROPOSALS,
  BRAND_STORIES,
  OUT_CAREERS
};

export default apiRoutes;
