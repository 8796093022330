import ScrollFadeInComponent from '@/components/layout/ScrollFadeInLayout';
import { StoresListProps } from '../types';
import useStoresListStyles from './hooks/useStoresListStyles';

export default function StoresList({ children }: StoresListProps) {
  const { storesListContainer } = useStoresListStyles();
  return (
    <ScrollFadeInComponent>
      <ul className={storesListContainer}>{children}</ul>
    </ScrollFadeInComponent>
  );
}
