/* eslint-disable no-param-reassign */
import { twJoin } from 'tailwind-merge';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import type { BannerItemProps } from '../types';

export default function BannerItem({ bannerVideo, sliderIndex, getVideoRef }: BannerItemProps) {
  const videoExtension = 'mp4';
  const videoType = 'video/mp4';
  return (
    <div
      className={useDeviceClasses({
        common: twJoin('flex justify-center'),
        desktop: 'h-800',
        mobile: 'h-600v'
      })}
    >
      <div className="absolute right-0 top-0 z-0 h-full w-full ">
        <video
          ref={(ref) => getVideoRef({ ref, index: sliderIndex })}
          autoPlay
          playsInline
          muted
          loop
          className="h-full w-full object-cover"
        >
          <track kind="captions" />
          <source src={`${bannerVideo}.${videoExtension}`} type={videoType} />
        </video>
      </div>
    </div>
  );
}
