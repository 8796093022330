/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
const { twJoin } = require('tailwind-merge');
const multiplesOfOne = Array.from({ length: 2000 }, (_, idx) => idx);


const spacing = multiplesOfOne.reduce((spacings, pixel) => {
  // eslint-disable-next-line no-param-reassign
  spacings[pixel] = `${Math.round((pixel / 16) * 1000) / 1000}rem`;
  spacings[pixel+'v'] = `${((pixel) / 375) * 100}vw`;;
  // spacings[pixel+'v'] = `${Math.round((pixel / 16) * 1000) / 1000}rem`;;
  return spacings;
}, {});

const spacingUnitViewport = (prefix) => Object.keys(spacing).reduce((acc, key) => {
  if (key.endsWith('v')) {
    acc[prefix + key] = spacing[key];
  }
  return acc;
}, {});


const pxToView  = {} 
const pxToRem  = {} 
//375px 기준
const pxToVwFn = () =>
  Array(200)
    .fill()
    .map(( _, i) => {
      pxToView[i+1] = `${((i + 1) / 375) * 100}vw`;
      // pxToView[i+1] = `${((i+1)/16).toFixed(2)}rem`;
    });
const pxToRemFn = () => 
  Array(200).fill().map(( _, i) => {
    pxToRem[i+1] = `${((i + 1) / 16).toFixed(3)}rem`;
  });
pxToVwFn()
pxToRemFn()
const fontPoppinsWith = (props) => twJoin('font-poppins', ...props);
module.exports = {
  multiplesOfOne,
  spacing,
  spacingUnitViewport,
  fontPoppinsWith,
  pxToView,
  pxToRem
};
