import type { FontSize } from './types';

// eslint-disable-next-line import/prefer-default-export
export const fontSizes: Record<FontSize, string> = {
  overline: 'text-overline',
  subInfo: 'text-subInfo',
  caption: 'text-caption',
  subject: 'text-subject',
  body: 'text-body',
  button: 'text-button',
  'body7-s': 'text-body7-s',
  'body7-m': 'text-body7-m',
  'body7-l': 'text-body7-l',
  'body8-s': 'text-body8-s',
  'body8-s-u': 'text-body8-s-u',
  'body8-m': 'text-body8-m',
  'body8-l': 'text-body8-l',
  'body8-l-u': 'text-body8-l-u',
  'body9-s': 'text-body9-s',
  'body9-m': 'text-body9-m',
  'body9-m-u': 'text-body9-m-u',
  'body9-l': 'text-body9-l',
  'body10-s': 'text-body10-s',
  'body10-s-u': 'text-body10-s-u',
  'body10-m': 'text-body10-m',
  'body10-l': 'text-body10-l',
  'body11-s': 'text-body11-s',
  'body11-s-u': 'text-body11-s-u',
  'body11-m': 'text-body11-m',
  'body11-l': 'text-body11-l',
  'body12-s': 'text-body12-s',
  'body12-m': 'text-body12-m',
  'body12-l': 'text-body12-l',
  't5-l': 'text-t5-l',
  't5-l-u': 'text-t5-l-u',
  't4-m': 'text-t4-m',
  't4-l': 'text-t4-l',
  't4-l-p': 'text-t4-l-p',
  't3-xl': 'text-t3-xl',
  't3-xl-p': 'text-t3-xl-p',
  't3-l': 'text-t3-l',
  't2-l': 'text-t2-l',
  't1-xl-01': 'text-t1-xl-01',
  't1-xl-02': 'text-t1-xl-02',
  't1-xl-p': 'text-t1-xl-p',
  'body10-xl': 'text-body10-xl',
  't4-xl': 'text-t4-xl',
  't-body': 'text-t-body',
  't3-xxl': ''
};
