import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useHelper } from '@/common/hooks/useHelper';
import { useLocale } from '@/common/hooks/useLocale';
import BtnMainLinkClick from '@/common/icons/BtnMainLinkClick';
import BtnMainOutClick from '@/common/icons/BtnMainOutClick';
import { helper } from '@/common/utils';
import Link from 'next/link';
import type { ReactNode } from 'react';
import type { PhrasesProps } from '../types';

export default function Phrases({ title, title2, subTitle, link }: PhrasesProps) {
  const isMobile = useIsMobile();
  const { isJp } = useLocale();
  const { getTextWithLineBreaks } = useHelper();
  const BtnMainLinkClickStyles = useDeviceClasses({ desktop: 'h-28 w-28', mobile: 'h-20v w-20v' });
  const BtnSvg: { [key: string]: ReactNode } = {
    CAREERS: (
      <BtnMainOutClick
        className={useDeviceClasses({ desktop: 'h-28 w-28', mobile: 'h-20v w-20v' })}
      />
    )
  };
  const languageMobileTitleFormat = () => {
    if (isJp)
      return (
        <div className="flex flex-col space-y-2 ">
          <h1>{getTextWithLineBreaks(title)}</h1>
          <h1>{getTextWithLineBreaks(title2)}</h1>
        </div>
      );
    return getTextWithLineBreaks([title, title2].join(' '));
  };
  const titleForamt = isMobile ? (
    languageMobileTitleFormat()
  ) : (
    <div className="flex flex-col space-y-2 font-pretendardJP">
      <h1>{getTextWithLineBreaks(title)}</h1>
      <h1>{getTextWithLineBreaks(title2)}</h1>
    </div>
  );
  return (
    <div
      className={useDeviceClasses({
        common: 'relative flex flex-col text-white',
        desktop: 'ml-auto  space-y-20',
        mobile: 'space-y-10v'
      })}
    >
      <Link
        aria-label={`move to ${subTitle} homepage`}
        href={link}
        className="pointer-events-auto flex w-fit items-center space-x-12"
        target={helper.isOutLink(link) ? '_blank' : '_self'}
      >
        <label
          className={useDeviceClasses({
            common: 'cursor-pointer',
            desktop: 'text-t7-xl',
            mobile: 'text-t6-xl-v'
          })}
        >
          {subTitle}
        </label>
        {BtnSvg[subTitle] || <BtnMainLinkClick className={BtnMainLinkClickStyles} />}
      </Link>
      <div
        className={useDeviceClasses({
          common: 'whitespace-pre-line   text-white',
          desktop: 'text-t2-xl-1',
          mobile: 'text-t1-xl-01-v',
          ENdesktop: 'font-poppins  text-t2-l',
          JPdesktop: 'font-pretendardJP text-t2-l',
          ENmobile: 'text-t2-xl-v',
          JPmobile: 'text-t2-xl-v'
        })}
      >
        {titleForamt}
      </div>
    </div>
  );
}
