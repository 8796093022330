import { extendTailwindMerge, validators } from 'tailwind-merge';
import { helpers } from '../utils';

const merge = extendTailwindMerge({
  theme: { borderRadius: [validators.isNumber] },
  classGroups: {
    'font-size': [
      'text-overline',
      'text-subInfo',
      'text-caption',
      'text-subject',
      'text-body',
      'text-button',
      'text-body7-s',
      'text-body7-m',
      'text-body7-l',
      'text-body8-s',
      'text-body8-m',
      'text-body8-l',
      'text-body8-en-l',
      'text-body8-xl',
      'text-body9-s',
      'text-body9-m',
      'text-body9-l',
      'text-body10-s',
      'text-body10-m',
      'text-body10-l',
      'text-body10-en-l',
      'text-body10-l-p',
      'text-body11-s',
      'text-body11-m',
      'text-body11-l',
      'text-body12-s',
      'text-body12-m',
      'text-body12-l',
      'text-t7-l',
      'text-t7-xl',
      'text-t6-m',
      'text-t6-l',
      'text-t5-xl',
      'text-t5-l',
      'text-t4-m',
      'text-t4-l',
      'text-t3-l',
      'text-t3-xl',
      'text-t3-xl-p',
      'text-t2-xl-1',
      'text-t2-xl-2',
      'text-t2-l',
      'text-t1-l',
      'text-t1-xl',
      'text-t1-xl-p',
      'text-t-body',
      'text-overline-v',
      'text-subInfo-v',
      'text-caption-v',
      'text-subject-v',
      'text-body-v',
      'text-button-v',
      'text-body7-l-v',
      'text-body7-m-v',
      'text-body8-m-v',
      'text-body8-l-v',
      'text-body9-l-v',
      'text-body9-xl-v',
      'text-body10-s-v',
      'text-body10-l-v',
      'text-body10-m-v',
      'text-body11-r-v',
      'text-body11-m-v',
      'text-body11-sl-v',
      'text-body11-l-v',
      'text-body12-r-v',
      'text-body12-m-v',
      'text-body12-l-v',
      'text-body13-s-v',
      'text-body13-m-v',
      'text-body13-l-v',
      'text-body14-m-v',
      'text-body16-l-v',
      'text-t7-m-v',
      'text-t7-l-v',
      'text-t7-l-v-p',
      'text-t6-xl-v',
      'text-t5-l-v',
      'text-t4-l-v',
      'text-t3-l-v',
      'text-t3-xl-v',
      'text-t3-l-v-p',
      'text-t2-l-v-p',
      'text-t2-xl-v',
      'text-t1-xl-01-v',
      'text-t1-xl-p-v',
      'text-ci-t1-l',
      'text-ci-t1-m',
      'text-ci-t2-l',
      'text-ci-t2-m',
      'text-body1-l',
      'text-body1-m'
    ],
    'space-x': Object.keys(helpers.spacingUnitViewport('space-x-')),
    'space-y': Object.keys(helpers.spacingUnitViewport('space-y-')),
    height: Object.keys(helpers.spacingUnitViewport('h-')),
    width: Object.keys(helpers.spacingUnitViewport('w-'))
  }
});
export default merge;
