import type { ReactElement } from 'react';

interface NewsTitleProps {
  title: string;
  children?: ReactElement;
}

export default function NewsTitle({ title, children }: NewsTitleProps) {
  return (
    <div className="flex space-x-6 text-body10-l">
      <h2 className=" mb-12">{title}</h2>
      {children}
    </div>
  );
}
