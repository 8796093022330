import { useRouter } from 'next/router';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useLayoutEffect } from '../useLayoutEffect';

export const useScrollTriggerReRender = (elId?: string) => {
  const router = useRouter();
  const scrollRefresh = () => {
    ScrollTrigger.refresh();
  };

  useLayoutEffect(() => {
    // 뒤로가기 시에 스크롤 재 감지 하기

    // 라우터 변경 이벤트 리스너 등록
    window.addEventListener('resize', scrollRefresh);
    router.events.on('routeChangeComplete', scrollRefresh);

    // 컴포넌트 언마운트 시 이벤트 리스너 해제
    return () => {
      window.removeEventListener('resize', scrollRefresh);
      router.events.off('routeChangeComplete', scrollRefresh);
    };
  }, [router.events]); // router.events를 의존성으로 지정하여 이벤트 리스너 갱신
  useLayoutEffect(() => {
    // pageTitle 요소의 변경을 감지하는 ResizeObserver 생성
    if (elId) {
      const el = document.getElementById(elId);
      const elObserver = new ResizeObserver(scrollRefresh);
      if (el) elObserver.observe(el);
      return () => {
        elObserver.disconnect(); // ResizeObserver 해제
      };
    }
  }, [elId]);
};
