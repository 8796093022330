import type { HTMLMotionProps, TargetAndTransition, Transition } from 'framer-motion';
import { motion } from 'framer-motion';
import { type ReactNode } from 'react';

interface ScrollFadeInComponentProps extends HTMLMotionProps<'div'> {
  children: ReactNode;
  transition?: Transition & { ease?: string; duration?: number };
  exitTransition?: TargetAndTransition;
  changeKey?: number | string;
}
export default function ScrollFadeInLayout({
  children,
  changeKey,
  transition,
  exitTransition,
  ...motionProps
}: ScrollFadeInComponentProps) {
  const defaultTransition: Transition & { ease?: string } = {
    duration: 0.7,
    ease: 'easeInOut'
  };

  const mergeTransition = { ...defaultTransition, ...transition, ...exitTransition };
  return (
    <motion.div
      key={changeKey}
      initial={{
        y: 60,
        opacity: 0
      }}
      exit={{ opacity: 0 }}
      className="z-1 w-full	"
      whileInView={{ y: 0, opacity: 1 }}
      transition={mergeTransition}
      viewport={{ once: true }}
      {...motionProps}
    >
      {children}
    </motion.div>
  );
}
