import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';

export default function useStyles() {
  const styles = {
    wrap: useDeviceClasses({
      common: 'relative z-[200]',
      desktop: 'w-375 min-h-375'
    }),
    logoContainer: useDeviceClasses({
      common: 'border-b border-b-grayscale-10',
      desktop: 'py-16 px-20',
      mobile: 'py-16v'
    }),
    logo: useDeviceClasses({ desktop: 'w-58 h-25', mobile: 'w-58v h-25v' }),
    container: useDeviceClasses({
      common: 'w-full h-full relative',
      desktop: 'w-600 h-546 bg-white rounded-10',
      mobile: 'w-335v max-h-584v top-[10%]'
    }),
    imageContainer: useDeviceClasses({
      common: 'relative flex',
      desktop: 'w-full h-full max-h-[486px]',
      mobile: 'w-full h-300v rounded-10v overflow-hidden'
    }),
    title: useDeviceClasses({
      common: 'border-b border-b-grayscale-1 border-b-2',
      desktop: 'pt-24 pb-12  text-body8-l',
      mobile: 'pt-24v pb-12v text-body9-l-v'
    }),
    descriptionInnerContainer: useDeviceClasses({
      desktop: 'pt-18 mb-20',
      mobile: 'pt-22v mb-20v'
    }),
    subTitle: useDeviceClasses({
      desktop: 'text-body9-l pb-16 ',
      mobile: 'text-t7-l-v pb-16v'
    }),
    description: useDeviceClasses({
      desktop: 'text-body11-s',
      mobile: 'text-body12-r-v'
    }),
    footer: useDeviceClasses({
      common: 'flex justify-center w-full',
      desktop: 'pb-28 pt-20',
      mobile: 'px-20v pb-28v pt-20v'
    }),
    footerPopup: useDeviceClasses({
      common: 'flex w-full',
      desktop: 'py-16 px-40',
      mobile: 'pt-20v justify-center text-white'
    }),
    button: useDeviceClasses({
      common: 'border px-0 ',
      desktop: 'w-96 h-48',
      mobile: 'w-96v h-48v'
    })
  };
  return { ...styles };
}
