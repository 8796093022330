import gsap from 'gsap';

const transformY = (el: React.MutableRefObject<HTMLElement | null>, y: number) => {
  if (!el.current) return;
  gsap.context(() => {
    gsap.to(el.current, {
      y,
      duration: 0.2
    });
  });
};
const imageAnimations = { transformY };
export default imageAnimations;
