import { merge } from '@/styles/tailwind/src';
import locales from '@/common/constants/locale';
import { useTranslation } from 'next-i18next';
import { useIsMobile } from '@/common/contexts/IsMobileContext';

interface UseDeviceClassesProps {
  mobile?: string;
  desktop?: string;
  common?: string;
  ENmobile?: string;
  ENdesktop?: string;
  ENcommmon?: string;
  JPmobile?: string;
  JPdesktop?: string;
  JPcommmon?: string;
}
const useDeviceClasses = (classes: UseDeviceClassesProps) => {
  const isMobile = useIsMobile();
  const { i18n } = useTranslation();
  const {
    mobile,
    desktop,
    common,
    ENcommmon,
    ENdesktop,
    ENmobile,
    JPcommmon,
    JPdesktop,
    JPmobile
  } = classes;
  const getStyles = (ln: string) => {
    switch (ln) {
      case locales.korea:
        return { mobile, desktop, common };
      case locales.english:
        return {
          common: merge(common, ENcommmon),
          desktop: merge(desktop, ENdesktop), // JPdesktop 삭제 23.10.27 by.ec
          mobile: merge(mobile, ENmobile) // JPmobile 삭제 23.10.27 by.ec
        };
      case locales.japan:
        return {
          common: merge(common, JPcommmon),
          desktop: merge(desktop, JPdesktop),
          mobile: merge(mobile, JPmobile)
        };
      default:
        return { mobile, desktop, common };
    }
  };

  const styles = getStyles(i18n.language);
  const deviceClass = merge(
    `${common || ''} ${isMobile ? styles.mobile || '' : styles.desktop || ''}`
  );

  return deviceClass;
};

export default useDeviceClasses;
