import apiRoutes from '@/common/constants/api/route';
import { useApiQuery } from '@/common/hooks/useApiQuery';
import { StoresResponse } from '@/models/main/stores/stores.model';

const useStores = () => {
  const { data, isLoading, isError } = useApiQuery<StoresResponse>({
    key: ['stores'],
    method: 'get',
    path: apiRoutes.BRAND_STORIES
  });

  const storesList = data?.result || [];
  return {
    isError,
    isLoading,
    storesList
  };
};

export default useStores;
