import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import { useBannerSliderVideo } from '@/common/store/state';
import { useRef, useState } from 'react';
import SwiperCore from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

SwiperCore.use([Navigation, Pagination, Autoplay]);
const FIRST_VIDEO_CURRENT_TIME = 11300;
const MS_TO_SECOND_MULTIPLY = 980;

interface IuseSwiperVideo {
  videoRefs: HTMLVideoElement[];
}
export default function useSwiperVideo({ videoRefs }: IuseSwiperVideo) {
  const fillAniTime = useRef<NodeJS.Timeout>();
  const slideButtonClickTime = useRef<NodeJS.Timeout>();
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const { currentSliderIndex, setCurrentSliderIndex } = useBannerSliderVideo();

  const handleSetCurrentTime = (time: number) => {
    if (time >= 0) return time * MS_TO_SECOND_MULTIPLY;
    return FIRST_VIDEO_CURRENT_TIME;
  };

  const handleChangeCurrentSliderIndex = (__swiper: SwiperCore) =>
    setCurrentSliderIndex(__swiper.realIndex);

  const loadSwiper = (_swiper: SwiperCore) => {
    setSwiper(_swiper);
    _swiper.autoplay.stop(); // 초기에는 autoplay 정지
  };
  const runProgressBar = (animationValue: string) => {
    clearTimeout(fillAniTime.current);
    const progressBarEl = document.querySelector('.progressBar') as HTMLElement | null;
    progressBarEl!.style.animation = 'none'; // 애니메이션 비활성화
    fillAniTime.current = setTimeout(() => {
      progressBarEl!.style.animation = animationValue; // 다시 애니메이션 설정
    }, 10);
  };

  const updateProgressBar = (currentVideo: HTMLVideoElement) => {
    if (!swiper) return;
    clearTimeout(slideButtonClickTime.current);
    const remainingTime = handleSetCurrentTime(currentVideo.duration);
    const animationValue = `fill ${remainingTime}ms linear forwards`;
    runProgressBar(animationValue);

    slideButtonClickTime.current = setTimeout(() => {
      const swiperNextEl: any = swiper.navigation.nextEl;
      swiperNextEl[0].click();
    }, remainingTime);
  };

  useLayoutEffect(() => {
    if (videoRefs.length <= 0 || !swiper) return;
    if (!videoRefs[currentSliderIndex]) return;

    const currentVideo = videoRefs[currentSliderIndex];
    updateProgressBar(currentVideo);

    return () => {
      clearTimeout(slideButtonClickTime.current);
      clearTimeout(fillAniTime.current);
    };
  }, [videoRefs, currentSliderIndex, swiper]);

  const swiperConfig = {
    loop: true,
    slidesPerView: 1,
    onSwiper: loadSwiper,
    speed: 500,
    navigation: {
      enabled: true,
      nextEl: '.nextNav',
      prevEl: '.prevNav'
    },
    modules: [Pagination, Navigation, Autoplay],
    onSlideChange: handleChangeCurrentSliderIndex,
    onBeforeInit: () => {
      swiper?.navigation.update();
    },
    className: 'mySwiper'
  };

  return { swiperConfig, handleSetCurrentTime };
}
