import { useBoolean } from '@/common/hooks/useBoolean';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useTranslation } from 'react-i18next';
import CheckboxWithText from '../CheckboxWIthText';
import DesktopView from '../DesktopView';
import MobileView from '../MobileView';

interface IfooterPopup {
  closePopup: (doSetCookie?: boolean) => void;
  handlePopupOpen: (visible: boolean) => void;
}
export default function FooterPopup({ closePopup, handlePopupOpen }: IfooterPopup) {
  const { on, value: isVisible } = useBoolean();
  const { t } = useTranslation('common');
  const styles = {
    closeLabel: useDeviceClasses({
      desktop: 'text-body10-l',
      mobile: 'text-body13-m-v underline underline-offset-4 text-grayscale-9'
    })
  };
  const handleClose = () => {
    on();
    closePopup(true);
  };
  const handleOnlyClose = () => handlePopupOpen(false);

  return (
    <>
      <DesktopView>
        <>
          <CheckboxWithText
            label={t('modal.notShowToday')}
            name="todayCheck"
            onChange={handleClose}
            checked={isVisible}
            className="border-grayscale-1"
            labelClassName="text-body11-s"
          />
          <button type="button" className={styles.closeLabel} onClick={handleOnlyClose}>
            {t('modal.close')}
          </button>
        </>
      </DesktopView>
      <MobileView>
        <div className=" flex items-center justify-center bg-transparent">
          <button
            tabIndex={-1}
            type="button"
            className={styles.closeLabel}
            onClick={handleOnlyClose}
            onTouchStart={handleOnlyClose}
          >
            {t('modal.notShowTodayMobile')}
          </button>
        </div>
      </MobileView>
    </>
  );
}
