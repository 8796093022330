import type { HTMLProps } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: HTMLProps<HTMLElement>['className'];
}

export default function SelectCircle({ className }: Props) {
  return (
    <span
      className={twMerge('absolute -right-8 -top-8 z-30 h-6 w-6 rounded-full bg-black', className)}
    />
  );
}
