import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const SwiperArrowHover: FC<Omit<IconProps, 'size'>> = (props) => {
  const { isHover, ...rest } = props;
  return (
    // <svg
    //   {...rest}
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="9"
    //   height="14"
    //   viewBox="0 0 9 14"
    //   fill="none"
    // >
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      {isHover && (
        <g filter="url(#filter0_b_7267_107884)">
          <circle cx="20.5" cy="20" r="20" fill="#36363D" fillOpacity="0.1" />
        </g>
      )}
      <path d="M23.5 14L17.5 20L23.5 26" stroke="white" strokeWidth="2" strokeLinecap="round" />
      {isHover && (
        <defs>
          <filter
            id="filter0_b_7267_107884"
            x="-19.5"
            y="-20"
            width="80"
            height="80"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_7267_107884"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_7267_107884"
              result="shape"
            />
          </filter>
        </defs>
      )}
    </svg>
  );
};

export default SwiperArrowHover;
