import { Tlocales } from '@/common/constants/locale';
import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { useLocale } from '../useLocale';

const useHelper = () => {
  const isMobile = useIsMobile();
  const { currentLanguage } = useLocale();
  const getSideComponentTypeNameMarginTop = ({
    Component,
    name
  }: {
    Component: JSX.Element;
    name: string;
  }) => {
    if (Component.type.name !== name) return 'mt-76';
    return 'mt-62';
  };
  const getTextWithLineBreaks = (text: string) => {
    if (!text) return '';
    if (!text.match(/|/)) return '';
    const words = text.split('|');
    if (words.length === 1) return text;
    return words
      .map((word) => {
        const isMobileRemove = word.includes('^MREMOVE^');
        // const isDesktopRemove = word.includes('^DREMOVE^');
        const cleanWord = word.replaceAll('^MREMOVE^', '').replaceAll('^DREMOVE^', '');
        const firstChars = cleanWord.slice(0, 2);
        if (['dm', 'md'].includes(firstChars)) {
          if (isMobileRemove) {
            return ` ${cleanWord.slice(1)}`;
          }
          return `\n${cleanWord.slice(2)}`;
        }
        if (isMobile) {
          if (cleanWord[0] === 'd') {
            return `${cleanWord.slice(1)}`;
          }
          if (cleanWord[0] === 'm') {
            return `\n${cleanWord.slice(1)}`;
          }
        } else {
          if (cleanWord[0] === 'd') {
            return `\n${cleanWord.slice(1)}`;
          }
          if (cleanWord[0] === 'm') {
            return `${cleanWord.slice(1)}`;
          }
        }
        return `${word}`;
      })
      .join('');
  };
  const getCurrentLanguageFont = () => {
    const font: { [key in Tlocales]: string } = {
      'ko-KR': 'font-pretendard',
      'en-US': 'font-pretendard',
      'ja-JP': 'font-pretendardJP'
    };

    return font[currentLanguage];
  };
  const iFrameOnLoadStyleWithCDN = (
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>,
    callback?: () => void
  ) => {
    callback && callback();
    const iframe = iframeRef.current;
    const pretendardCDN = `@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");`;
    const metaUTF8 = '<meta charset="UTF-8">';
    if (iframe) {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow!.document;
      const styleElement = iframeDocument.createElement('style');
      styleElement.textContent = pretendardCDN;
      // 기존의 스타일 요소에 CSS 추가
      const existHeadElement = iframeDocument.querySelector('head');
      const existingStyleElement = iframeDocument.querySelector('head style');
      if (existHeadElement) existHeadElement.insertAdjacentHTML('afterbegin', metaUTF8);
      if (existingStyleElement) {
        existingStyleElement.textContent = pretendardCDN + existingStyleElement.textContent;
      } else {
        iframeDocument.head.appendChild(styleElement);
      }
    }
  };

  return {
    getTextWithLineBreaks,
    getCurrentLanguageFont,
    getSideComponentTypeNameMarginTop,
    iFrameOnLoadStyleWithCDN
  };
};
export default useHelper;
