import apiRoutes from '@/common/constants/api/route';
import { useApiQuery } from '@/common/hooks/useApiQuery';
import { PressReleasesRequest, PressReleasesResponse } from '@/models/pr/pressRelease.model';

export const usePressRelease = (params: PressReleasesRequest) => {
  const { data, isError, isLoading } = useApiQuery<PressReleasesResponse>({
    key: ['pressRelease', params.page, params.category, params.query, params.year],
    method: 'get',
    datas: { params },
    path: apiRoutes.PRESS_RELEASES
  });

  const pressReleaseList = data?.result || [];
  const totalCount = data?.totalCount || 0;
  return {
    isError,
    isLoading,
    pressReleaseList,
    totalCount
  };
};
