import { useBoolean } from '@/common/hooks/useBoolean';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import { useLocale } from '@/common/hooks/useLocale';
import { useScrollTriggerReRender } from '@/common/hooks/useScrollTriggerReRender';
import BtnMainMore from '@/common/icons/BtnMainMore';
import { useCurretEventPop, useMainBannerIndex } from '@/common/store/state';
import SelectCircle from '@/components/unit/SelectCircle';
import StaticImage from '@/components/unit/StaticImage';
import { motion } from 'framer-motion';
import { gsap } from 'gsap';
import Link from 'next/link';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import type { PlayMainTab, PlaySliderItems } from '../types';

const defaultObjectStyle: { [key: number]: {} } = {
  0: {
    scale: 1.6,
    transformOrigin: '47% 48%'
  },
  1: {
    scale: 1.1,
    transformOrigin: '55% top'
  },
  2: {
    scale: 1.1,
    objectPosition: '37% 50%'
  },
  3: {
    scale: 1.3,
    objectPosition: 'center'
  },
  4: {
    scale: 1,
    objectPosition: '62% center'
  }
};
const hoverObjectStyle: { [key: number]: {} } = {
  0: {
    scale: 1.25,
    transformOrigin: '96% 51%'
  },
  1: {
    scale: 1.24
  },
  2: {
    transformOrigin: '98px 27%',
    scale: 1.2
    // objectPosition: '98px 27%'
  },
  3: {
    scale: 1.4
  },
  4: {
    scale: 1.1
  }
};

interface BannerAnimation2Props {
  sliderItems: PlaySliderItems[];
}
export default function BannerAnimation2({ sliderItems }: BannerAnimation2Props) {
  const {
    on: imageExtendAniCompleteOn,
    off: imageExtendAniCompleteOff,
    value: imageExtendAniComplete
  } = useBoolean();
  const { currentEventPop } = useCurretEventPop();
  const { activeBannerIndex, setActiveBannerIndex } = useMainBannerIndex();
  const { isJp, isEn } = useLocale();
  const [hoverdImageIndex, setHoverdImageIndex] = useState<number>(-1);
  const firstRender = useRef(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const tabContainerRef = useRef<HTMLLIElement[]>([]);
  const imagesRef = useRef<HTMLImageElement[]>([]);
  const imagesContainerRef = useRef<HTMLLIElement[]>([]);
  const widthOfImage = 224;
  const gapBetweenImages = 20;

  imagesContainerRef.current = new Array(5)
    .fill(null)
    .map((_, i) => imagesContainerRef.current[i] || createRef());
  const getDistance = (hoveredImageIndex: number) => {
    return (widthOfImage + gapBetweenImages) * hoveredImageIndex;
  };

  const gameLinkBreak = () => {
    if (isJp) return [3, 8];
    if (isEn) return [3, 8];
    return [6];
  };
  const bannerFooterContentLineBreak: Partial<Record<PlayMainTab, { lineBreak: number[] }>> = {
    Game: {
      lineBreak: gameLinkBreak()
    },
    Contents: {
      lineBreak: [2]
    }
  };

  const hoveredImageAni = (index: number) => {
    if (index === hoverdImageIndex) return;
    firstRender.current = false;
    if (!imagesRef.current || !imagesContainerRef.current || !containerRef.current) return;
    gsap.context(() => {
      gsap.to(imagesRef.current[index], hoverObjectStyle[index]);

      imagesRef.current
        .filter((_, imageIndex) => imageIndex !== index)
        .forEach((image, filterImageIndex) => {
          gsap.to(image, defaultObjectStyle[filterImageIndex]);
        });
      gsap.to(containerRef.current, {
        x: `-${getDistance(index)}px`
      });
      gsap.to(imagesContainerRef.current[index], {
        width: 1200,
        duration: 0.6
      });

      gsap.to(
        imagesContainerRef.current.filter((_, idx) => idx !== index),

        { width: 224, duration: 0.6 }
      );
    }, [imagesRef.current]);
  };
  const handleLinkClick = () => setActiveBannerIndex(hoverdImageIndex);
  const handleImageClick = (index: number) => {
    setHoverdImageIndex(index);
    hoveredImageAni(index);
  };

  // const handleImageClick = (index: number) => {};
  const handleTabClick = (index: number) => {
    setHoverdImageIndex(index);
    hoveredImageAni(index);
  };

  useEffect(() => {
    if (!containerRef.current || imagesContainerRef.current.length === 0) {
      return;
    }

    const images = imagesContainerRef.current;
    const firstFocusImage = () => {
      if (!imagesRef.current || !containerRef.current) return;
      gsap.context(() => {
        gsap.set(imagesRef.current[0], {
          scale: 1.25,
          transformOrigin: '96% 51%'
        });
        imagesRef.current
          .filter((_, imageIndex) => imageIndex !== 0)
          .forEach((image, filterImageIndex) => {
            gsap.set(image, defaultObjectStyle[filterImageIndex]);
          });
        gsap.to(images[0], {
          width: 1200,
          height: 526,
          duration: 0.7,
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top center'
          },
          onComplete: () => {
            imageExtendAniCompleteOn();
            setHoverdImageIndex(0);
            // handleImageHover();
          }
        });
      }, [images]);
    };

    const runAnimations = async () => {
      if (!tabContainerRef.current) return;
      if (activeBannerIndex > -1) {
        if (currentEventPop) {
          handleImageClick(activeBannerIndex);
          imageExtendAniCompleteOn();
        } else firstFocusImage();
      } else firstFocusImage();
    };
    runAnimations();
  }, []);
  useScrollTriggerReRender();
  useLayoutEffect(() => {
    return () => {
      const initImage = () => {
        if (!imagesContainerRef.current) return;
        gsap.context(() => {
          gsap.to(imagesContainerRef.current, { width: 224, duration: 0.6 });
        }, [imagesContainerRef.current]);
      };
      initImage();
      imageExtendAniCompleteOff();
      setHoverdImageIndex(-1);
    };
  }, [imageExtendAniCompleteOff]);

  const bottomTextStyle = useDeviceClasses({
    common:
      'absolute bottom-24 left-1/2  z-20  flex  w-full -translate-x-1/2 flex-wrap justify-center text-body10-m text-white',
    desktop: 'max-w-[901px]',
    ENdesktop: 'max-w-[891px]',
    JPdesktop: 'max-w-[841px]'
  });
  const styles = {
    title: useDeviceClasses({
      desktop:
        'absolute left-1/2 top-1/2  z-20 flex w-max -translate-x-1/2 -translate-y-1/2 flex-col items-center text-t5-xl text-white',
      ENdesktop: 'text-t5-l'
    })
  };
  return (
    <div>
      <div className="flex w-full " ref={containerRef}>
        <ul className="flex space-x-20">
          {sliderItems.map((sliderItem, index) => {
            const activeIndexAfterZigzag = hoverdImageIndex === index;
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
              <li
                key={sliderItem.title + index.toString()}
                ref={(el) => el !== null && (imagesContainerRef.current[index] = el)}
                onClick={() => handleImageClick(index)}
                className={twJoin(
                  'relative h-526 w-224 cursor-pointer overflow-hidden rounded-10 '
                )}
              >
                <div
                  className={twJoin(
                    'absolute left-0 top-0 z-10 h-full w-full cursor-auto',
                    activeIndexAfterZigzag ? 'gradient-overlay' : 'cursor-pointer',
                    firstRender.current && index === 0 ? 'gradient-overlay' : ''
                  )}
                />
                <StaticImage
                  ref={(el) => el !== null && (imagesRef.current[index] = el)}
                  loading="eager"
                  className="rounded-10 object-cover"
                  // style={
                  // activeIndexAfterZigzag
                  // ? hoverObjectStyle[hoverdImageIndex] || undefined
                  // defaultObjectStyle[index]
                  // }
                  fill
                  sizes="auto"
                  src={sliderItem.service.main.image}
                  alt={sliderItem.service.main.title}
                />

                {/* <Hidden isHidden={!activeIndexAfterZigzag} className="delay-500"> */}
                <motion.div
                  hidden={!activeIndexAfterZigzag}
                  key={hoverdImageIndex}
                  initial={{ visibility: 'hidden', opacity: 0, willChange: 'auto' }}
                  animate={{ visibility: 'visible', opacity: 1 }}
                  transition={{ duration: 0.15, delay: 0.3 }}
                >
                  <Link
                    aria-label={`move to ${sliderItem.title} homepage`}
                    onClick={handleLinkClick}
                    href={sliderItem.service.main.link}
                    className="flex items-center"
                  >
                    <div className={styles.title}>
                      <h1>{sliderItem.title}</h1>
                      <div className="flex items-center ">
                        <h1 className="pr-16 font-poppins text-t5-l">
                          {sliderItem.service.main.title}
                        </h1>
                        <BtnMainMore className="h-40 w-40" />
                      </div>
                    </div>
                  </Link>
                  <div className={bottomTextStyle}>
                    {sliderItem.service.subs.map((item, subIndex) => {
                      const mainTitle = sliderItem.service.main.title;
                      const mainLink = sliderItem.service.main.link;
                      const notExistContour =
                        bannerFooterContentLineBreak[mainTitle]?.lineBreak.includes(subIndex);
                      const lastLink = subIndex === sliderItem.service.subs.length - 1;
                      return (
                        <React.Fragment key={item.title}>
                          <article>
                            <div className="flex items-center">
                              <Link onClick={handleLinkClick} href={mainLink + item.link || ''}>
                                {item.title}
                              </Link>
                              {!notExistContour && !lastLink && (
                                <div className=" mx-7 h-12 w-1 bg-white opacity-30" />
                              )}
                            </div>
                          </article>
                          {notExistContour && <div className="w-full" />}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </motion.div>
              </li>
            );
          })}
        </ul>
      </div>
      <ul className="mt-32 flex  font-poppins text-t7-l text-grayscale-8 first-of-type:ml-60">
        {sliderItems.map((sliderItem, index) => {
          const currentTab = hoverdImageIndex === index;
          const { main } = sliderItem.service;
          const buttonPostion: { [key: number]: string } = {
            1: 'ml-89',
            2: 'ml-45',
            3: 'ml-61',
            4: 'ml-70'
          };
          return (
            <li
              ref={(ref) => {
                if (ref === null) return;
                tabContainerRef.current[index] = ref;
              }}
              className={twMerge('relative', 'text-grayscale-1', buttonPostion[index])}
              key={main.title}
            >
              {currentTab && <SelectCircle className="-right-15 top-0 h-12 w-12" />}
              <button
                className={twMerge(
                  'text-t4-xl font-poppins text-grayscale-8',
                  currentTab && 'text-grayscale-1',
                  !imageExtendAniComplete ? 'cursor-default' : ''
                )}
                type="button"
                onClick={() => imageExtendAniComplete && handleTabClick(index)}
              >
                {sliderItem.service.main.title}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
