import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const RightArrow2: FC<Omit<IconProps, 'size'>> = (props) => {
  const { fill, ...rest } = props;

  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
    >
      <path d="M1 5L12 5" stroke={fill || '#212126'} strokeWidth="1.5" />
      <path
        d="M8 9L12 5.03704L8 1"
        stroke={fill || '#212126'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default RightArrow2;
