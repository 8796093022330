import Title from '@/components/unit/Title';

import { useHelper } from '@/common/hooks/useHelper';
import useStores from '@/services/main/stores/stores.service';
import type { StoresProps } from '../types';
import MainTitle from './MainTitle';
import StoreItem from './StoresItem';
import StoresList from './StoresList';
import StoresSkeleton from './StoresSkeleton';
import useStyles from './hooks/useStyles';

export default function StoresSection({ title, subTitle }: StoresProps) {
  const styles = useStyles();
  const { getTextWithLineBreaks } = useHelper();
  const { storesList, isLoading, isError } = useStores();

  const isSkeleton = isLoading || isError;
  return (
    <section className={styles.container}>
      <Title
        type="mainMobileType2"
        title={<MainTitle title={title} />}
        titleClassName={styles.titleContainer}
        subTitleClassName={styles.subTitleContainer}
        subTitle={getTextWithLineBreaks(subTitle)}
        containerClassName={styles.titleWrapContainer}
      />
      <StoresList>
        <StoresSkeleton on={isSkeleton}>
          {storesList.map((content, index) => {
            return (
              <StoreItem
                key={content.storyNo}
                content={content}
                isLast={index === storesList.length - 1}
              />
            );
          })}
        </StoresSkeleton>
      </StoresList>
    </section>
  );
}
