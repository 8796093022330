import { ReactNode } from 'react';

interface IBannerTitleContainer {
  children: ReactNode;
  containerClassName: string;
}
export default function BannerTitleContainer({
  children,
  containerClassName
}: IBannerTitleContainer) {
  return <div className={containerClassName}>{children}</div>;
}
