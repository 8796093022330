import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import SkeletonThemeBox from '@/components/unit/SkeletonThemeBox';
import React, { FC, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface StoresSkeletonProps {
  on: boolean;
  children: ReactNode;
}

interface StyleProps {
  [index: number]: string;
}

const StoresSkeletonLayout: FC<StoresSkeletonProps> = ({ on = false, children }) => {
  const style = {
    wrapper: useDeviceClasses({
      mobile: 'w-276v h-256v',
      desktop: 'w-378 h-310',
      common: 'leading-none flex flex-col items-start'
    }),
    line1: useDeviceClasses({
      mobile: '!w-276v h-194v !rounded-10v',
      desktop: '!w-378 h-240 !rounded-10',
      common: ''
    }),
    line2: useDeviceClasses({
      mobile: '!w-80v h-12v mt-20v mb-6v !rounded-4v',
      desktop: '!w-120 h-12 mt-23 mb-7 !rounded-4',
      common: ''
    })
  };

  const line3: StyleProps = {
    1: useDeviceClasses({
      mobile: '!w-240v h-16v mt-4v',
      desktop: '!w-268 h-16 mt-6 !rounded-4',
      common: ''
    }),
    2: useDeviceClasses({
      mobile: '!w-220v h-16v mt-4v',
      desktop: '!w-236 h-16 mt-6 !rounded-4',
      common: ''
    }),
    3: useDeviceClasses({
      mobile: '!w-252v h-16v mt-4v',
      desktop: '!w-294 h-16 mt-6 !rounded-4',
      common: ''
    })
  };
  if (!on) return children;

  return Array(3)
    .fill(null)
    .map((_, i) => {
      return (
        <li className={style.wrapper} key={`${i.toString()}`}>
          <SkeletonThemeBox>
            <Skeleton className={style.line1} />
            <Skeleton className={style.line2} />
            <Skeleton className={line3[(i % 3) + 1]} />
          </SkeletonThemeBox>
        </li>
      );
    });
};

export default StoresSkeletonLayout;
