import ScrollFadeInComponent from '@/components/layout/ScrollFadeInLayout';
import { merge } from '@/styles/tailwind/src';
import StaticImage from '@/components/unit/StaticImage';

interface CardProps {
  imageUrl: string;
  title: string;
  content: string;
  containerClassName?: string;
}

export default function ImageCard({ title, content, imageUrl, containerClassName }: CardProps) {
  return (
    <figure className={merge('relative mb-80v', containerClassName)}>
      <ScrollFadeInComponent>
        <div className="relative h-320v w-full overflow-hidden rounded-10">
          <StaticImage
            placeholder="empty"
            src={imageUrl}
            alt={title}
            fill
            loading="eager"
            className="object-cover"
            sizes="auto"
          />
        </div>
        <figcaption className="y-8v pt-15v text-center">
          <strong className="font-poppins text-t7-l-v-p">{title}</strong>
          <p className="pt-8v text-body11-m-v text-grayscale-3">{content}</p>
        </figcaption>
      </ScrollFadeInComponent>
    </figure>
  );
}
