import ScrollFadeInComponent from '@/components/layout/ScrollFadeInLayout';
import Phrases from './Phrases';
import { BannerSliderProps } from '../types';

interface IBannerTitle {
  currentSliderIndex: number;
  currentSlide: BannerSliderProps;
}
export default function BannerTitle({ currentSlide, currentSliderIndex }: IBannerTitle) {
  return (
    <ScrollFadeInComponent changeKey={currentSliderIndex} transition={{ delay: 0.2 }}>
      <Phrases
        title2={currentSlide?.title2}
        link={currentSlide?.link}
        title={currentSlide?.title}
        subTitle={currentSlide?.subTitle}
      />
    </ScrollFadeInComponent>
  );
}
