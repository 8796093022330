import layout from '@/common/constants/layout';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { merge } from '@/styles/tailwind/src';
import { twJoin } from 'tailwind-merge';

export default function useStyles() {
  const styles = {
    container: useDeviceClasses({
      desktop: merge(
        'mx-auto flex-col space-y-80 pb-140 text-center',
        layout.mixins.flexJustifyCenter
      ),
      common: layout.mixins.displayCenter,
      mobile: 'mt-80v flex w-full flex-col pb-20v'
    }),
    titleWrapContainer: useDeviceClasses({ mobile: 'px-20v', JPmobile: 'items-start' }),
    title: useDeviceClasses({ mobile: 'mr-auto', JPmobile: '!w-240v' }),
    titleContainer: useDeviceClasses({
      desktop: twJoin(layout.mixins.displayCenter, 'space-x-16 font-pretendard'),
      mobile: 'text-t3-xl-v',
      JPmobile: 'w-full text-left'
    }),
    subTitleContainer: useDeviceClasses({
      desktop: 'text-center',
      JPmobile: '!w-254v'
    }),
    link: useDeviceClasses({ mobile: 'relative bottom-[5vw] translate-y-1/2' }),
    btnOutLink: useDeviceClasses({ mobile: 'h-24v w-24v' })
  };
  return styles;
}
