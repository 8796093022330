import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';

export default function useStoresItemStyles() {
  const styles = {
    cardCategory: useDeviceClasses({
      mobile: 'mb-2v text-body13-l-v',
      desktop: 'text-body10-l',
      common: 'text-grayscale-5'
    }),
    cardTitle: useDeviceClasses({
      mobile: 'text-body11-l-v',
      desktop: 'text-body9-l group',
      common: 'transition duration-500 ease-in-out line-clamp-1'
    }),
    cardLast: useDeviceClasses({
      mobile: 'pr-20v'
    })
  };
  return styles;
}
