import { Swiper as SwiperComponent } from 'swiper/react';
import useSwiperVideo from './hooks/useSwiperVideo';

interface ICustomSwiper {
  children: React.ReactNode;
  videoRefs: HTMLVideoElement[];
}

// gitIndex

export default function CustomSwiper({ children, videoRefs }: ICustomSwiper) {
  const { swiperConfig } = useSwiperVideo({
    videoRefs
  });
  return <SwiperComponent {...swiperConfig}>{children}</SwiperComponent>;
}
