import type { ReactElement } from 'react';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import ScrollFadeInComponent from '@/components/layout/ScrollFadeInLayout';
import merge from '@/styles/tailwind/src/merge';

type TitleType = 'mainMobileType2' | 'default';
interface ClassNames {
  subTitleClassName?: string;
  titleClassName?: string;
  containerClassName?: string;
}
interface TitleProps extends ClassNames {
  changeKey?: string | number;
  motionType?: 'scrollIn' | 'fadeIn';
  motionTransition?: object;
  type?: TitleType;
  title: ReactElement | string;
  subTitle: ReactElement | string;
  isActiveAni?: boolean;
}

export default function Title({
  title,
  changeKey,
  motionType = 'scrollIn',
  motionTransition,
  type = 'default',
  isActiveAni = true,
  subTitle,
  subTitleClassName,
  titleClassName,
  containerClassName
}: TitleProps) {
  const initialMotion = {
    fadeIn: {
      initial: { opacity: isActiveAni ? 0 : 1 }
    },
    scrollIn: {
      initial: isActiveAni
        ? {
            y: 60,
            opacity: 0
          }
        : {
            y: 0,
            opacity: 0
          }
    }
  };
  const typeClasses: { [key in TitleType]: { [innerKey in keyof ClassNames]: string } } = {
    default: {
      containerClassName: '',
      titleClassName: '',
      subTitleClassName: ''
    },
    mainMobileType2: {
      containerClassName: useDeviceClasses({ desktop: 'mb-0', mobile: 'space-y-12v' }),
      titleClassName: useDeviceClasses({
        mobile: 'flex w-full items-end text-left text-t3-xl-v font-default'
      }),
      subTitleClassName: useDeviceClasses({
        desktop: 'text-left text-body9-m',
        mobile: 'w-full text-left text-body10-m-v text-grayscale-3'
      })
    }
  };
  return (
    <ScrollFadeInComponent
      changeKey={changeKey}
      transition={motionTransition || undefined}
      {...initialMotion[motionType]}
    >
      <div
        className={useDeviceClasses({
          common: 'flex w-full flex-col items-center',
          desktop: merge(
            'relative z-20 mb-100 space-y-8',
            typeClasses[type].containerClassName,
            containerClassName
          ),
          mobile: merge(
            'text-center',
            'space-y-12',
            typeClasses[type].containerClassName,
            containerClassName
          )
        })}
      >
        <article
          className={useDeviceClasses({
            common: '',
            desktop: merge(
              'font-poppins  text-t3-xl',
              typeClasses[type].titleClassName,
              titleClassName
            ),
            mobile: merge(
              'w-full font-poppins text-t3-l-v-p',
              typeClasses[type].titleClassName,
              titleClassName
            )
          })}
        >
          {title}
        </article>
        <article
          className={merge(
            useDeviceClasses({
              common: 'text-grayscale-3',
              desktop: merge(typeClasses[type].subTitleClassName),
              mobile: merge('text-center text-body10-m-v', typeClasses[type].subTitleClassName)
            }),
            subTitleClassName
          )}
        >
          {subTitle}
        </article>
      </div>
    </ScrollFadeInComponent>
  );
}
