/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios, { isAxiosError } from 'axios';
import { errorMessage } from '@/common/constants/api/errorMessage';

const Axios = axios.create({
  baseURL: 'api/',
  headers: {
    common: {
      Accept: 'application/json',
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_AUTH_KEY}`,
      'Content-Type': 'application/json'
    }
  }
});

const getHttpHeaders = (isAuthenticated = false): AxiosRequestConfig => {
  if (isAuthenticated) {
    return {
      headers: {
        Authorization: 'Bearer YOUR_TOKEN'
      }
    };
  }

  return {};
};

const get = <T = any>(
  path: string,
  config?: AxiosRequestConfig<any>
): Promise<AxiosResponse<T>> => {
  return Axios.get(path, config);
};

const del = (path: string): Promise<AxiosResponse> => Axios.delete(path);

const post = <T = any>(
  path: string,
  data: T,
  config?: AxiosRequestConfig<any> | undefined
): Promise<AxiosResponse> => Axios.post(path, data, config);

const put = (path: string, data: any): Promise<AxiosResponse> => Axios.post(path, data);

const patch = (path: string, data: any): Promise<AxiosResponse> =>
  Axios.post(path, data, getHttpHeaders());

const handleError = (err: unknown) => {
  if (process.env.NODE_ENV !== 'production') {
    if (isAxiosError(err) && err.response !== undefined) {
      const { status, data } = err.response;
      if (data && data.header) {
        console.error(errorMessage[data.header.resultCode] || data.header.resultMessage);
        // alert(errorMessage[data.header.resultCode] || data.header.resultMessage);
        return errorMessage[data.header.resultCode] || data.header.resultMessage;
      }
      console.error(errorMessage[status]);
      return errorMessage[status];
    }
  }
  return Promise.resolve(err);
};

export default {
  get,
  del,
  post,
  put,
  patch,
  handleError
};
