/* eslint-disable react/no-unstable-nested-components */
import Images from '@/common/constants/Images';
import common from '@/common/constants/common';
import { useHelper } from '@/common/hooks/useHelper';
import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import route from '@/common/route';
import { useBannerSliderVideo } from '@/common/store/state';
import { helper } from '@/common/utils';
import { useMemo, useRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import type { BannerSliderProps, BannerVideoProps, MainProps } from '../types';
import BannerItem from './BannerItem';
import BannerTitle from './BannerTitle';
import BannerTitleContainer from './BannerTitleContainer';
import CustomSwiper from './CustomSwiper';
import MaskContainer from './MaskContainer';
import useStyles from './hooks/useStyles';

export default function BannerSlider({ mainList }: { mainList: MainProps }) {
  const { setCurrentSliderIndex } = useBannerSliderVideo();
  const { getTextWithLineBreaks } = useHelper();
  const videoRefs = useRef<HTMLVideoElement[]>([]);
  const { currentSliderIndex } = useBannerSliderVideo();
  const { container, innerContainer } = useStyles({ currentSliderIndex });

  const dynamicBannerVideo = (index: number) =>
    helper.imagePrefix(`/videos/main/mainBanner${helper.padIndex(index + 1)}`);
  const bannerLinkList = useMemo(
    () => [common.ciBrandUrl, route.esg.tabs.esgManagement, route.careers.path],
    []
  );
  const newSliderItems: BannerSliderProps[] = mainList.bannerSliders.map((sliderItem, index) => ({
    ...sliderItem,
    sliderId: index + 1,
    bannerVideo: dynamicBannerVideo(index),
    link: bannerLinkList[index],
    title: getTextWithLineBreaks(sliderItem.title)
  }));

  const currentSlide = newSliderItems[currentSliderIndex];

  const getVideoRef = ({ ref, index }: BannerVideoProps) => {
    if (ref) videoRefs.current[index] = ref;
  };
  useLayoutEffect(() => {
    return () => setCurrentSliderIndex(0);
  }, []);

  const BannerItemContainer = newSliderItems?.map((sliderItem, index) => (
    <SwiperSlide key={sliderItem.title}>
      <BannerItem
        {...sliderItem}
        key={sliderItem.title}
        getVideoRef={getVideoRef}
        sliderIndex={index}
      />
    </SwiperSlide>
  ));

  return (
    <section className={container}>
      <CustomSwiper videoRefs={videoRefs.current}>{BannerItemContainer}</CustomSwiper>
      <BannerTitleContainer containerClassName={innerContainer}>
        <BannerTitle currentSlide={currentSlide} currentSliderIndex={currentSliderIndex} />
      </BannerTitleContainer>
      <MaskContainer imgSrc={Images.main.mask} slidersLength={newSliderItems.length} />
    </section>
  );
}
