import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { twJoin } from 'tailwind-merge';

interface SkeletonThemeBoxProps {
  children: React.ReactNode;
  className?: string;
  wrapperBox?: boolean;
}

const SkeletonThemeBox: React.FC<SkeletonThemeBoxProps> = ({
  children,
  wrapperBox = false,
  className
}) => {
  const styles = {
    container: twJoin('flex flex-col', className)
  };
  return (
    <SkeletonTheme baseColor="#E9E9E9" duration={2}>
      {wrapperBox ? <div className={styles.container}>{children}</div> : children}
    </SkeletonTheme>
  );
};

export default SkeletonThemeBox;
