import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';

export default function useStoresListStyles() {
  const styles = {
    storesListContainer: useDeviceClasses({
      desktop: 'flex justify-between',
      mobile:
        'mt-30v flex w-full space-x-16v overflow-y-clip overflow-x-auto pl-20v pb-26v scrollbar-hide'
    })
    // mobile ios15 버전 이하에서 px-20v로 우측에 간격이 벌어지지 않는 점 발견 Pl-20v 이후 Item last pr-20로 대체
  };
  return styles;
}

// [&>*:nth-child(3)]:pr-20v
