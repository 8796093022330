import Images from '@/common/constants/Images';
import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useHelper } from '@/common/hooks/useHelper';
import { helper } from '@/common/utils';
import DesktopView from '@/components/unit/DesktopView';
import MobileView from '@/components/unit/MobileView';
import Title from '@/components/unit/Title';
import MainPageSectionLayout from '../MainPageSectionLayout';
import type { PlayProps } from '../types';
import BannerAnimation2 from './BannerAnimation2';
import ImageCard from './mobile/ImageCard';

export default function PlayStyleSection({ sliderItems, subTitle }: PlayProps) {
  const isMobile = useIsMobile();
  const { getTextWithLineBreaks } = useHelper();
  const playImageKeys = Object.keys(Images.main).filter((image) =>
    image.startsWith('playBanner00')
  );
  const playMobileImageKeys = Object.keys(Images.main).filter((image) =>
    image.startsWith('mobilePlay')
  );
  const expandedPlayMobileImage = helper.sortAndExpandArray(playMobileImageKeys, 5);
  const dynamicIndexImage = (index: number) =>
    isMobile ? expandedPlayMobileImage[index] || playImageKeys[index] : playImageKeys[index];

  const newObject = sliderItems.slice(0);
  const sliderItemsWithImage = newObject.map((sliderItem, index) => {
    const newObjectSub = Object.assign(sliderItem, {});
    newObjectSub.service.main.image = Images.main[dynamicIndexImage(index)];
    newObjectSub.title = getTextWithLineBreaks(newObjectSub.title);
    return newObjectSub;
  });

  return (
    <MainPageSectionLayout desktopClassName="space-y-66">
      <>
        <Title
          type="mainMobileType2"
          title="Weaving New Play. NHN"
          titleClassName="justify-center"
          subTitleClassName={useDeviceClasses({ common: 'text-center' })}
          subTitle={getTextWithLineBreaks(subTitle)}
        />
        <DesktopView>
          <BannerAnimation2 sliderItems={sliderItemsWithImage} />
        </DesktopView>
        <MobileView>
          <section className="mt-60v grid grid-cols-2 gap-27v px-20v">
            {sliderItemsWithImage.map(({ title, service }, index) => {
              const isEven = (index + 1) % 2 === 0;
              return (
                <ImageCard
                  containerClassName={isEven ? 'top-180v' : ''}
                  key={title}
                  title={service.main.title}
                  imageUrl={service.main.image}
                  content={title}
                />
              );
            })}
          </section>
        </MobileView>
      </>
    </MainPageSectionLayout>
  );
}
