import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const BtnMainOutClick: FC<Omit<IconProps, 'size'>> = (props) => {
  const { ...rest } = props;
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM16.4737 13.5175L16.4854 18.0943C16.4868 18.6466 16.9356 19.0931 17.4879 19.0917C18.0402 19.0903 18.4868 18.6415 18.4853 18.0892L18.4673 11L18.4647 10L17.4647 10.0026L10.4117 10.0207C9.85944 10.0221 9.41287 10.4709 9.41429 11.0232C9.41571 11.5755 9.86457 12.0221 10.4169 12.0207L15.1543 12.0085L9 18.1628L10.4142 19.577L16.4737 13.5175Z"
        fill="white"
      />
    </svg>
  );
};

export default BtnMainOutClick;
