import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const LinkButtonArrow: FC<Omit<IconProps, 'size'>> = (props) => {
  const { ...rest } = props;
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
    >
      <path d="M1.5 8.89844L9.27817 1.12026" stroke="#212126" strokeWidth="1.5" />
      <path
        d="M9.57129 6.48438L9.59748 0.85371L3.91443 0.827522"
        stroke="#212126"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LinkButtonArrow;
