import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const TopArrowPolygon: FC<Omit<IconProps, 'size'>> = (props) => {
  const { fill, ...rest } = props;
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
    >
      <path
        d="M4.39624 0.774735C4.58739 0.434909 5.07667 0.434908 5.26782 0.774734L8.9129 7.25487C9.10038 7.58817 8.85952 8 8.47711 8H1.18695C0.804541 8 0.563685 7.58817 0.751167 7.25487L4.39624 0.774735Z"
        fill={fill || '#FB2828'}
      />
    </svg>
  );
};

export default TopArrowPolygon;
