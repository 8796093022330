import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { merge } from '@/styles/tailwind/src';
import { twJoin } from 'tailwind-merge';

interface IProps {
  name: string;
  label: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  className: string;
  labelClassName?: string;
  disabled?: boolean;
}
const CheckboxWithText: React.FC<IProps> = ({
  name,
  label,
  className,
  labelClassName,
  disabled = false,
  checked = false,
  onChange
}) => {
  const handleChange = () => {
    if (!disabled) {
      onChange(!checked);
    }
  };
  const containerStyles = useDeviceClasses({
    common: twJoin('user-none checkboxLabel2  flex items-center relative ', className),
    JPmobile: 'items-start',
    ENmobile: 'items-start'
  });
  const labelStyles = merge(
    useDeviceClasses({
      desktop: 'ml-10 text-body10-m',
      ENmobile: 'ml-8v  text-left relative -top-3v',
      JPmobile: 'ml-8v  text-left relative -top-3v',
      mobile: 'flex items-center text-body11-r-v ml-8v'
    }),
    labelClassName
  );
  return (
    <>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        id={name}
        name={name}
        className="customCheckbox2"
      />
      <label htmlFor={name} className={containerStyles}>
        <button type="button" onClick={handleChange} className={labelStyles}>
          {label}
        </button>
      </label>
    </>
  );
};

export default CheckboxWithText;
