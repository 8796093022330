import StaticImage from '@/components/unit/StaticImage';
import useStyles from './hooks/useStyles';
import { SwiperNav } from './SwiperNav';

interface IMaskContainer {
  slidersLength: number;
  imgSrc: string;
}
export default function MaskContainer({ imgSrc, slidersLength }: IMaskContainer) {
  const { imageContainer, image } = useStyles();
  return (
    <>
      <div className={imageContainer}>
        <SwiperNav total={slidersLength} />
      </div>
      <StaticImage
        placeholder="empty"
        loading="eager"
        fill
        priority
        src={imgSrc}
        sizes="auto"
        className={image}
        alt="mask"
      />
    </>
  );
}
