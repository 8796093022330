import { useIsMobile } from '@/common/contexts/IsMobileContext';

interface DesktopViewProps {
  children: React.ReactElement;
}

function DesktopView({ children }: DesktopViewProps) {
  const isMobile = useIsMobile();
  if (!isMobile) {
    return children;
  }
  return null;
}

export default DesktopView;
