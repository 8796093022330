import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { merge } from '@/styles/tailwind/src';
import { MainPageLayoutProps } from './types';

function MainPageSectionLayout(
  { mobileClassNames, desktopClassName, children }: MainPageLayoutProps,
  ref?: ForwardedRef<any>
) {
  const styles = {
    container: useDeviceClasses({
      desktop: merge(
        'mx-auto flex h-auto flex-col justify-center pb-240 text-center',
        desktopClassName
      ),
      mobile: merge('w-full', mobileClassNames)
    })
  };
  return (
    <section ref={ref} className={styles.container}>
      {children}
    </section>
  );
}
export default forwardRef(MainPageSectionLayout);
