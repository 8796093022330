import { helper } from '@/common/utils';
import { useRouter } from 'next/router';
import type { ReactElement } from 'react';
import { twJoin } from 'tailwind-merge';

interface PostNavProps {
  href: string;
  text: string;
  index: number;
  icon: ReactElement;
}
export default function PostNav({ text, icon, href, index }: PostNavProps) {
  const router = useRouter();
  const activeBorderBottom = index === 2 ? '' : 'border-b border-grayscale-10';
  return (
    <li className=" h-65v  w-full px-20v">
      <button
        type="button"
        onClick={() => (helper.isOutLink(href) ? window.open(href) : router.push(href))}
        className={twJoin('flex h-65v w-full items-center', activeBorderBottom)}
      >
        <div className="flex w-full items-center justify-between ">
          <strong className="text-body10-l-v">{text}</strong>
          <span>{icon}</span>
        </div>
      </button>
    </li>
  );
}
