import common from '@/common/constants/common';

export default function useStoresLogic() {
  const getStoryLink = (id: number) =>
    common.isDev
      ? `http://dev.inside.nhn.com/br/storyDetail.nhn?no=${id}`
      : `https://inside.nhn.com/br/storyDetail.nhn?no=${id}`;

  return { getStoryLink };
}
