import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import { helper } from '@/common/utils';
import ImageCard from '@/components/unit/ImageCard';
import Link from 'next/link';
import { useRef } from 'react';
import { StoresItemrops } from '../types';
import useStoresItemStyles from './hooks/useStoresItemStyles';
import useStoresLogic from './hooks/useStoresLogic';

export default function StoreItem({ content, isLast }: StoresItemrops) {
  const textRef = useRef<HTMLParagraphElement>(null);
  const { getStoryLink } = useStoresLogic();
  const { cardCategory, cardTitle, cardLast } = useStoresItemStyles();

  useLayoutEffect(() => {
    textRef.current!.insertAdjacentHTML('afterbegin', content.title);
  }, [content.title]);
  return (
    <li key={content.storyNo} className={isLast ? cardLast : ''}>
      <Link
        aria-label={`move to external Inside Content ${getStoryLink(content.storyNo)}`}
        href={getStoryLink(content.storyNo)}
        target="_blank"
      >
        <ImageCard
          src={helper.getApiImageUrl(content.thumbnailImage)}
          alt={content.title}
          isActiveAni={false}
        >
          <div className="flex flex-col text-left ">
            <span className={cardCategory}>
              {content.category[0] + content.category.substring(1).toLocaleLowerCase()}
            </span>
            <p ref={textRef} className={cardTitle} />
          </div>
        </ImageCard>
      </Link>
    </li>
  );
}
