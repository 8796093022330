import type { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import HttpApiService from '@/lib/HttpApiService';

interface UseApiQueryProps<T extends QueryKey, K> {
  key: T;
  path: string;
  datas?: any;
  method: 'get' | 'post' | 'patch' | 'get' | 'del';
  options?: UseQueryOptions<K, unknown, K, T>;
}
const useApiQuery = <Result>({
  key,
  path,
  method,
  options,
  datas
}: UseApiQueryProps<QueryKey, Result>) =>
  useQuery<Result, unknown, Result, QueryKey>(
    key,
    () => HttpApiService[method](path, datas).then((res) => res.data),
    {
      ...options,
      retry: 0
    }
  );

export default useApiQuery;
