import apiRoutes from '@/common/constants/api/route';
import { useApiQuery } from '@/common/hooks/useApiQuery';
import type { StockInfoResponse, StockInfoResult } from '@/models/ir/stock/stock.model';
import type {
  DailyTransactionStatus,
  DailyTransactionStatusRequest,
  DailyTransactionStatusResponse
} from '@/models/ir/stock/stockStatuses.model';

export const useStockInformation = () => {
  const { data, isLoading, isError } = useApiQuery<StockInfoResponse>({
    key: ['stockInformation'],
    method: 'get',
    path: apiRoutes.IR_STOCKINFORMATION
  });

  const stockInformations = (data?.result as StockInfoResult) || [];

  return {
    stockInformations,
    isLoading,
    isError
  };
};

interface UseDailyTransactionStatusProps {
  params: DailyTransactionStatusRequest;
}

export const useDailyTransactionStatus = ({ params }: UseDailyTransactionStatusProps) => {
  const { data } = useApiQuery<DailyTransactionStatusResponse>({
    key: ['dailyTransactionStatus', params],
    method: 'get',
    datas: { params },
    path: apiRoutes.IR_STOCKSTATUSES,
    options: {
      keepPreviousData: true
    }
  });

  const dailyTransactionStatus = (data?.result as DailyTransactionStatus[]) || [];
  const totalCount = data?.totalCount || 0;

  return {
    dailyTransactionStatus,
    totalCount
  };
};
