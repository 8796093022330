import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import Title from '@/components/unit/Title';

interface ServicesSectionTitleLayoutProps {
  title: string;
  subTitle: string;
}
export default function ServicesSectionTitleLayout({
  title,
  subTitle
}: ServicesSectionTitleLayoutProps) {
  const contentLeftClassName = 'text-left w-full font-pretendard';
  return (
    <div className={useDeviceClasses({ mobile: 'px-20v w-full' })}>
      <Title
        type="mainMobileType2"
        title={<span className={useDeviceClasses({ mobile: 'mr-auto' })}>{title}</span>}
        subTitleClassName={contentLeftClassName}
        titleClassName={contentLeftClassName}
        subTitle={<p className={useDeviceClasses({ desktop: 'mb-32' })}>{subTitle}</p>}
      />
    </div>
  );
}
