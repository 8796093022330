import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import { useLocale } from '@/common/hooks/useLocale';
import Close from '@/common/icons/Close';
import { Dialog } from '@headlessui/react';
import Cookies from 'js-cookie';
import { MouseEvent, useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import Button from '../Button/Button';
import StaticImage from '../StaticImage';
import FooterPopup from './FooterPopup';
import useStyles from './useStyles';

interface IModal {
  type?: 'confirm' | 'popup';
  onClick?: () => void;
  href?: string;
}
export default function ModalImage({ onClick, type = 'popup', href }: IModal) {
  const { currentLanguage } = useLocale();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const styles = useStyles();
  const handlePopupOpen = (visible: boolean) => setIsOpen(visible);
  const handleClick = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    setTimeout(() => {
      handlePopupOpen(false);
      onClick && onClick();
    }, 50);
  };

  const typeLayout = {
    confirm: {
      button: true,
      subTitle: true,
      footerPopup: 'justify-center'
    },
    popup: {
      button: false,
      subTitle: false,
      footerPopup: 'border-t-grayscale-10 border-t justify-between'
    }
  };
  const closePopup = (doSetCookie?: boolean) => {
    handlePopupOpen(false);
    if (doSetCookie) Cookies.set('popupClosed', 'true', { expires: 1 });
  };
  useLayoutEffect(() => {
    if (type === 'popup') {
      if (!Cookies.get('popupClosed')) handlePopupOpen(true);
    } else handlePopupOpen(true);
  }, [type]);
  const imageUrls = useMemo(() => {
    if (isMobile) return `/images/main/popup/mobile/${currentLanguage}.png`;
    return `/images/main/popup/${currentLanguage}.png`;
  }, [currentLanguage]);

  const innerContainerClassNames = useDeviceClasses({
    common: 'fixed inset-0 flex w-screen items-center justify-center',
    mobile: 'px-20v'
  });
  return (
    <Dialog
      open={isOpen}
      tabIndex={-1}
      id="modal"
      onClose={() => handleClick()}
      className={styles.wrap}
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className={innerContainerClassNames}>
        <Dialog.Panel className={styles.container}>
          {isMobile && (
            <div className="flex h-32v w-full justify-end">
              <button
                type="button"
                aria-label="close"
                tabIndex={-1}
                className="flex h-20v justify-end text-white"
                onClick={handleClick}
              >
                <Close fill="#dbdbdb" />
              </button>
            </div>
          )}
          <a
            className={styles.imageContainer}
            href={href}
            tabIndex={-1}
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage placeholder="empty" fill src={imageUrls} alt="modal image" />
          </a>

          <div
            data-autofocus
            id="modalFooter"
            className={twJoin(styles.footerPopup, !isMobile && typeLayout[type].footerPopup)}
          >
            {typeLayout[type].button ? (
              <Button
                type="submit"
                text="확인"
                styleType="white"
                className={styles.button}
                onClick={handleClick}
              />
            ) : (
              <FooterPopup handlePopupOpen={handlePopupOpen} closePopup={closePopup} />
            )}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
