import TopArrowPolygon from '@/common/icons/TopArrowPolygon';

export default function useStockHelper({ fluctuationType }: { fluctuationType: string }) {
  const up = ['1', '2', '6', '7'];
  const down = ['4', '5', '8', '9'];

  const getComparedToPreviousPriceIcon = (type: string) => {
    let result = <> </>;
    if (up.includes(type)) {
      result = <TopArrowPolygon />;
    }
    if (down.includes(type)) {
      result = <TopArrowPolygon fill="#125DE6" className="rotate-180" />;
    }
    return result;
  };
  const getFluctuationRateIcon = (type: string) => {
    let result = '';
    if (up.includes(type)) {
      result = '+';
    }
    if (down.includes(type)) {
      result = '-';
    }
    return result;
  };

  const textColor = (type: string) => {
    let result = 'text-grayscale-1';
    if (up.includes(type)) {
      result = 'text-primary-selected';
    }
    if (down.includes(type)) {
      result = 'text-primary-blue';
    }
    return result;
  };

  return {
    icon: getComparedToPreviousPriceIcon(fluctuationType),
    sign: getFluctuationRateIcon(fluctuationType),
    textColor: textColor(fluctuationType)
  };
}
