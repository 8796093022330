import layout from '@/common/constants/layout';
import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { useAniDisabled, useGnbSticky, useOutClick } from '@/common/store/state';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import { gsap } from 'gsap';
import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLayoutEffect } from '../useLayoutEffect';
import { useScrollTriggerReRender } from '../useScrollTriggerReRender';

let globalIsSticky = false;
let pageIntroBannerInView = true;
const useScrollAnimation = (disabled?: boolean) => {
  useScrollTriggerReRender();
  const { gnbHeight, headerHeight } = layout;
  const isMobile = useIsMobile();
  const { scrollY } = useScroll({ layoutEffect: false });
  const { isSticky } = useGnbSticky();
  const { aniDisabled } = useAniDisabled();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isActive: openSelectBox } = useOutClick();
  const { ref: inViewRef, inView } = useInView({ threshold: 0.25 });

  const sideTabPosition = (value: number) => {
    const sideTabLayout = document.getElementById('sideTabLayout');
    if (!sideTabLayout) return;
    gsap.context(() => {
      gsap.to(sideTabLayout, {
        y: value,
        duration: 0
      });
    }, [sideTabLayout]);
  };
  const initHeader = () => {
    const header = document.querySelector('#header');
    const gnb = document.querySelector('#gnb');
    if (!header || !gnb) return;
    gsap.to(header, { top: 0 });
    gsap.to(gnb, { top: gnbHeight - 1 });
  };

  const hideHeader = () => {
    const header = document.querySelector('#header');
    const gnb = document.querySelector('#gnb');
    if (!header || !gnb) return;
    gsap.to(header, { top: -headerHeight });
    gsap.to(gnb, { top: 0 });
  };

  useLayoutEffect(() => {
    globalIsSticky = isSticky;
    if (!isSticky) {
      sideTabPosition(0);
    }
  }, [isSticky]);

  useLayoutEffect(() => {
    pageIntroBannerInView = inView;
  }, [inView]);
  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (aniDisabled && isSticky) return;
    if (isMobile || openSelectBox) return;
    const header = document.querySelector('#header');
    const gnb = document.querySelector('#gnb');

    if (latest < 0) return;
    const isScrollDown = scrollY.getPrevious() - latest < 0;
    const scrollDirection = isScrollDown ? 'down' : 'up';

    if (disabled) return;
    if (!gnb || !header) return;

    if (scrollDirection === 'down') {
      if (latest < 60) return;
      gsap.to(header, { top: -headerHeight });
      gsap.to(gnb, { top: 0 });
      sideTabPosition(0);
    }

    if (scrollDirection === 'up') {
      gsap.to(header, { top: 0 });
      gsap.to(gnb, { top: gnbHeight - 1 });
      if (!pageIntroBannerInView) {
        sideTabPosition(globalIsSticky ? gnbHeight : 0);
      } else {
        sideTabPosition(0);
      }
    }
  });

  return {
    initHeader,
    hideHeader,
    inViewRef,
    containerRef
  };
};

export default useScrollAnimation;
