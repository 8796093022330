import common from '@/common/constants/common';
import layout from '@/common/constants/layout';
import locales from '@/common/constants/locale';
import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import useScrollAnimation from '@/common/hooks/useScrollAnimation';
import type { ExtendedNextPage } from '@/common/types/next';
import { helper } from '@/common/utils';
import BannerSlider from '@/components/specific/Main/BannerSlider';
import NewsSection from '@/components/specific/Main/NewsSection';
import PlayStyleSection from '@/components/specific/Main/PlayStyleSection';
import ServicesSection from '@/components/specific/Main/ServicesSection';
import StoresSection from '@/components/specific/Main/StoresSection';
import type { MainProps } from '@/components/specific/Main/types';
import ModalImage from '@/components/unit/ModalImage';
import type { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { useMemo } from 'react';

const Main: ExtendedNextPage = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation(['main', 'common']);
  const { initHeader } = useScrollAnimation(true);
  const contentSectionsStyle = useDeviceClasses({
    mobile: 'py-80v',
    desktop: layout.desktopLayout
  });

  const mainList: MainProps = useMemo(() => t('main:main', { returnObjects: true }), [t]);

  useLayoutEffect(() => {
    if (!isMobile) initHeader();
  }, [isMobile]);

  return (
    <>
      <Head>
        <title>NHN</title>
        <meta name="description" content={t('common:title.description')} />
        <meta property="og:url" content={helper.getUrl()} />
        <meta property="og:site_name" content="NHN" />
        <meta property="og:title" content="NHN" />
        <meta property="og:description" content={t('common:title.description')} />
        <meta
          property="og:image"
          content={helper.imagePrefixWithQueryParameter('/NHN.png', { v: '0.1' })}
        />
      </Head>
      <ModalImage href={common.ciBrandUrl} />
      <h1 className="visuallyHidden">NHN</h1>
      <section className="min-h-screen overflow-x-hidden">
        <BannerSlider mainList={mainList} />
        <NewsSection {...mainList.news} />
        <div className={contentSectionsStyle}>
          <PlayStyleSection {...mainList.play} />
          <ServicesSection {...mainList.services} />
          <StoresSection {...mainList.peoples} />
        </div>
      </section>
    </>
  );
};

Main.hidePageWithGnbLayout = true;
export const getServerSideProps: GetServerSideProps = async ({ locale = locales.korea }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'main', 'footer'])),
      path: '/'
    }
  };
};
export default Main;
