import { helper } from '@/common/utils';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import layout from '@/common/constants/layout';
import { useBoolean } from '@/common/hooks/useBoolean';
import SwiperArrowHover from '@/common/icons/SwiperArrowHover';
import { twJoin } from 'tailwind-merge';
import { motion } from 'framer-motion';
import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { useBannerSliderVideo } from '@/common/store/state';
import { useRef, useState } from 'react';

interface INav {
  total: number;
}

export function SwiperNav({ total }: INav) {
  const isMobile = useIsMobile();
  const { currentSliderIndex } = useBannerSliderVideo();
  const [, setNavClick] = useState(false);
  const { off: leftOff, on: leftOn, value: leftHover } = useBoolean();
  const { off: rightOff, on: rightOn, value: rightHover } = useBoolean();
  const prevNavEl = useRef<HTMLButtonElement>(null);
  const nextNavEl = useRef<HTMLButtonElement>(null);
  const handleClickNav = () => setNavClick((prev) => !prev);

  return (
    <div
      className={useDeviceClasses({
        common: 'absolute flex w-full items-center justify-between text-white',
        desktop: 'bottom-100 left-80 w-285 text-t-body transition-bottom desktopM:bottom-80',
        mobile: 'body10-xl-v bottom-20v left-20v z-10 justify-between pr-20v text-body11-l-v'
      })}
    >
      <div className={useDeviceClasses({ common: 'flex items-center' })}>
        <span className={useDeviceClasses({ desktop: 'w-20', mobile: 'w-20v' })}>
          {helper.padIndex(currentSliderIndex + 1)}
        </span>
        <div
          className={useDeviceClasses({
            common: 'relative',
            desktop: 'mx-12 w-120',
            mobile: 'mx-12v w-80v'
          })}
        >
          <div
            className={useDeviceClasses({
              common: 'progressBar absolute top-0 bg-white',
              mobile: 'h-2v',
              desktop: 'h-2'
            })}
          />
          <div
            className={useDeviceClasses({
              common: 'w-full bg-white opacity-40',
              desktop: 'h-2',
              mobile: 'h-2v'
            })}
          />
        </div>
        <span>{helper.padIndex(total)}</span>
      </div>
      <div
        className={useDeviceClasses({
          common: 'relative flex',
          desktop: 'right-0 space-x-8',
          mobile: 'right-20v space-x-8v'
        })}
      >
        <motion.button
          aria-label="prevButton"
          onTouchStart={leftOn}
          onTouchEnd={leftOff}
          onMouseEnter={!isMobile ? leftOn : undefined}
          onMouseLeave={!isMobile ? leftOff : undefined}
          type="button"
          ref={prevNavEl}
          className={useDeviceClasses({
            common: twJoin(`prevNav ${layout.mixins.displayCenter} duration-300`),
            desktop: 'h-40 w-40',
            mobile: 'h-40v w-40v'
          })}
          onClick={handleClickNav}
        >
          <SwiperArrowHover
            isHover={leftHover}
            className={useDeviceClasses({
              desktop: 'h-40 w-40',
              mobile: 'h-40v w-40v'
            })}
          />
        </motion.button>
        <motion.button
          ref={nextNavEl}
          aria-label="nextButton"
          onTouchStart={rightOn}
          onTouchEnd={rightOff}
          onMouseEnter={!isMobile ? rightOn : undefined}
          onMouseLeave={!isMobile ? rightOff : undefined}
          onClick={handleClickNav}
          type="button"
          className={useDeviceClasses({
            common: twJoin(`nextNav ${layout.mixins.displayCenter} duration-300 `),
            desktop: 'h-40 w-40',
            mobile: 'h-40v w-40v'
          })}
        >
          <SwiperArrowHover
            isHover={rightHover}
            className={useDeviceClasses({
              desktop: 'h-40 w-40',
              mobile: 'h-40v w-40v',
              common: 'rotate-180'
            })}
          />
        </motion.button>
      </div>
    </div>
  );
}
