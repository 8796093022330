import Image from 'next/image';
import type { ReactElement } from 'react';
import React, { useRef } from 'react';
import ScrollFadeInComponent from '@/components/layout/ScrollFadeInLayout';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useBoolean } from '@/common/hooks/useBoolean';
import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import { twJoin } from 'tailwind-merge';
import { useIsMobile } from '@/common/contexts/IsMobileContext';
import animations from '@/common/constants/animations';

type ImageCardProps = {
  src: string;
  alt: string;
  children: ReactElement;
  isActiveAni?: boolean;
};

export default function ImageCard(props: ImageCardProps) {
  const isMobile = useIsMobile();
  const {
    imageAnimations: { transformY }
  } = animations;
  const { on, off, value: isHover } = useBoolean();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const { alt, children, isActiveAni = true, ...rest } = props;

  useLayoutEffect(() => {
    if (isMobile) return;
    if (isHover) transformY(containerRef, -10);
    else transformY(containerRef, 0);
  }, [isHover]);
  return (
    <ScrollFadeInComponent initial={!isActiveAni ? { y: 0, opacity: 1 } : undefined}>
      <figure
        onMouseEnter={on}
        onMouseLeave={off}
        className={useDeviceClasses({
          common: 'group',
          desktop: 'w-378',
          mobile: 'w-276v'
        })}
      >
        <div
          ref={containerRef}
          className={useDeviceClasses({
            common: 'relative border border-grayscale-8 border-opacity-20',
            desktop: twJoin(
              'mb-16 h-240 w-378 rounded-10',
              'group-hover:shadow-imageHoverDropdown'
            ),
            mobile: 'mb-16v h-194v w-277v rounded-10v'
          })}
        >
          <Image
            alt={alt}
            fill
            placeholder="empty"
            {...rest}
            sizes="(min-width: 768px) 30vw, 100vw"
            className={useDeviceClasses({
              common: ' object-cover transition duration-500 ease-in-out ',
              mobile: 'h-full w-full rounded-10v',
              desktop: 'h-full w-full rounded-10 '
            })}
          />
        </div>
        {children}
      </figure>
    </ScrollFadeInComponent>
  );
}
