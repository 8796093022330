const images = '/images';
const main = '/main';
const service = '/service';
const company = '/company';
const ir = '/ir';
const esg = '/esg';
export interface ImagesType {
  main: {
    [key: string]: string;
  };
  company: {
    about: {
      intro: string;
      [key: string]: string;
    };
    sffiliates: {
      domesticIntro: string;
      overseasIntro: string;
      mobileDomesticIntro: string;
      mobileOverseasIntro: string;
      logo: {
        [key: string]: string;
      };
    };
    life: {
      intro: string;
      mobileIntro: string;
      passion: string;
      collaboration: string;
      autonomy: string;
      persistence: string;
      trustyourself: string;
    };
    history: {
      intro: string;
      [key: string]: string;
    };
  };
  service: {
    [key: string]: string;
  };
  ir: {
    schedule: {
      selectTime: string;
      [key: string]: string;
    };
  };
  esg: {
    esgManagement: {
      [key: string]: string;
    };
    environment: {
      [key: string]: string;
    };
    society: {
      [key: string]: string;
    };
    governance: {
      [key: string]: string;
    };
    businessConduct: {
      [key: string]: string;
    };
    reportPolicy: {
      [key: string]: string;
    };
  };
  ci: {
    application: {
      [key: string]: string;
    };
  };
}

const Images: ImagesType = Object.freeze({
  main: {
    mask: `${images}${main}/mask.png`,
    mainBanner01: `${images}${main}/mainBanner01.png?v=1`,
    mainBanner02: `${images}${main}/mainBanner02.png?v=1`,
    mainBanner03: `${images}${main}/mainBanner03.png?v=1`,
    mainBanner04: `${images}${main}/mainBanner04.png?v=4`,
    mobileMainBanner01: `${images}${main}/mobileMainBanner01.png?v=2`,
    mobileMainBanner02: `${images}${main}/mobileMainBanner02.png?v=2`,
    mobileMainBanner03: `${images}${main}/mobileMainBanner03.png?v=2`,
    mobileMainBanner04: `${images}${main}/mobileMainBanner04.png?v=2`,
    playBanner01: `${images}${main}/playBanner01.png?v=2`,
    playBanner02: `${images}${main}/playBanner02.png?v=3`,
    playBanner03: `${images}${main}/playBanner03.png?v=4`,
    playBanner04: `${images}${main}/playBanner04.png?v=2`,
    playBanner05: `${images}${main}/playBanner05.png?v=4`,
    playBanner001: `${images}${main}/playBanner001.png`,
    playBanner002: `${images}${main}/playBanner002.png`,
    playBanner003: `${images}${main}/playBanner003.png`,
    playBanner004: `${images}${main}/playBanner004.png`,
    playBanner005: `${images}${main}/playBanner005.png`,
    mobilePlayBanner01: `${images}${main}/mobilePlayBanner01.png?v=3`,
    mobilePlayBanner02: `${images}${main}/mobilePlayBanner02.png?v=3`,
    mobilePlayBanner03: `${images}${main}/mobilePlayBanner03.png?v=3`,
    mobilePlayBanner04: `${images}${main}/mobilePlayBanner04.png?v=3`,
    mobilePlayBanner05: `${images}${main}/mobilePlayBanner05.png?v=3`,
    storesImage01: `${images}${main}/storesImage01.png`,
    storesImage02: `${images}${main}/storesImage02.png`,
    storesImage03: `${images}${main}/storesImage03.png`
  },
  company: {
    about: {
      gradient: `${images}${company}/about/gradient.png`,
      intro: `${images}${company}/about/intro.png?v=4`,
      intro2: `${images}${company}/about/intro2.png`,
      mobileintro: `${images}${company}/about/mobileintro.png?v=4`,
      mobileintro2: `${images}${company}/about/mobileIntro2.png`,
      mobileLocation: `${images}${company}/about/companyLocation.png`,
      playImage1: `${images}${company}/about/playImage1.png?v=3`,
      playImage2: `${images}${company}/about/playImage2.png?v=3`,
      playImage3: `${images}${company}/about/playImage3.png?v=3`,
      playImage4: `${images}${company}/about/playImage4.png?v=3`,
      playImage5: `${images}${company}/about/playImage5.png?v=3`,
      playImage6: `${images}${company}/about/playImage6.png?v=1`,
      playImage7: `${images}${company}/about/playImage7.png?v=1`,
      playImage8: `${images}${company}/about/playImage8.png?v=1`,
      mobilePlayImage1: `${images}${company}/about/mobilePlayImage1.png`,
      mobilePlayImage2: `${images}${company}/about/mobilePlayImage2.png`,
      mobilePlayImage3: `${images}${company}/about/mobilePlayImage3.png`,
      mobilePlayImage4: `${images}${company}/about/mobilePlayImage4.png`,
      mobilePlayImage5: `${images}${company}/about/mobilePlayImage5.png`,
      mobilePlayImage6: `${images}${company}/about/mobilePlayImage6.png`,
      mobilePlayImage7: `${images}${company}/about/mobilePlayImage7.png`,
      mobilePlayImage8: `${images}${company}/about/mobilePlayImage8.png`,
      mobileLocationImage1: `${images}${company}/about/mobileLocationImage1.png?v=1`,
      mobileLocationImage2: `${images}${company}/about/mobileLocationImage2.png?v=1`,
      mobileLocationImage3: `${images}${company}/about/mobileLocationImage3.png?v=1`,
      mobileLocationImage4: `${images}${company}/about/mobileLocationImage4.png?v=1`,
      mobileLocationImage5: `${images}${company}/about/mobileLocationImage5.png?v=1`,
      mobileLocationImage6: `${images}${company}/about/mobileLocationImage6.png?v=1`,
      mobileLocationImage7: `${images}${company}/about/mobileLocationImage7.png?v=1`,
      locationImage1: `${images}${company}/about/locationImage1.png?v=1`,
      locationImage2: `${images}${company}/about/locationImage2.png?v=1`,
      locationImage3: `${images}${company}/about/locationImage3.png?v=1`,
      locationImage4: `${images}${company}/about/locationImage4.png?v=1`,
      locationImage5: `${images}${company}/about/locationImage5.png?v=1`,
      locationImage6: `${images}${company}/about/locationImage6.png?v=1`,
      locationImage7: `${images}${company}/about/locationImage7.png?v=1`
    },
    sffiliates: {
      domesticIntro: `${images}${company}/sffiliates/domesticIntro.png`,
      overseasIntro: `${images}${company}/sffiliates/overseasIntro.png`,
      mobileDomesticIntro: `${images}${company}/sffiliates/mobileDomesticIntro.png`,
      mobileOverseasIntro: `${images}${company}/sffiliates/mobileOverseasIntro.png`,
      logo: {
        ad: `${images}${company}/sffiliates/logo/ad.png`,
        ace: `${images}${company}/sffiliates/logo/ace.png`,
        ibt: `${images}${company}/sffiliates/logo/ibt.png`,
        asia: `${images}${company}/sffiliates/logo/asia.png`,
        academy: `${images}${company}/sffiliates/logo/academy.png`,
        bugs: `${images}${company}/sffiliates/logo/bugs.png`,
        cloud: `${images}${company}/sffiliates/logo/cloud.png`,
        commerce: `${images}${company}/sffiliates/logo/commerce.png`,
        comico: `${images}${company}/sffiliates/logo/comico.png`,
        comicokorea: `${images}${company}/sffiliates/logo/comicoKorea.png`,
        crossent: `${images}${company}/sffiliates/logo/crossent.png`,
        diquest: `${images}${company}/sffiliates/logo/diquest.png`,
        doctortour: `${images}${company}/sffiliates/logo/drtour.png`,
        dooray: `${images}${company}/sffiliates/logo/dooray.png`,
        doppleSoft: `${images}${company}/sffiliates/logo/doppleSoft.png`,
        data: `${images}${company}/sffiliates/logo/data.png`,
        injeinc: `${images}${company}/sffiliates/logo/injeinc.png`,
        enterprise: `${images}${company}/sffiliates/logo/enterprise.png`,
        edu: `${images}${company}/sffiliates/logo/edu.png`,
        fashiongokorea: `${images}${company}/sffiliates/logo/fashiongo.png`,
        fukuoka: `${images}${company}/sffiliates/logo/fukuoka.png`,
        global: `${images}${company}/sffiliates/logo/global.png`,
        japan: `${images}${company}/sffiliates/logo/japan.png`,
        kcp: `${images}${company}/sffiliates/logo/kcp.png`,
        link: `${images}${company}/sffiliates/logo/link.png`,
        service: `${images}${company}/sffiliates/logo/service.png`,
        payco: `${images}${company}/sffiliates/logo/payco.png`,
        playart: `${images}${company}/sffiliates/logo/playArt.png`,
        techorus: `${images}${company}/sffiliates/logo/techorus.png`,
        taiwan: `${images}${company}/sffiliates/logo/taiwan.png`,
        nhn: `${images}${company}/sffiliates/logo/nhn.png`,
        wetoo: `${images}${company}/sffiliates/logo/wetoo.png`,
        wisdomHouse: `${images}${company}/sffiliates/logo/wisdomHouse.png`,
        ikonic: `${images}${company}/sffiliates/logo/ikonic.png`
      }
    },
    life: {
      intro: `${images}${company}/life/intro.png`,
      mobileIntro: `${images}${company}/life/mobileIntro.png`,
      passion: `${images}${company}/life/passion.png`,
      collaboration: `${images}${company}/life/collaBoration.png`,
      autonomy: `${images}${company}/life/autonomy.png`,
      persistence: `${images}${company}/life/persistence.png`,
      trustyourself: `${images}${company}/life/trustYourself.png`
    },
    history: {
      intro: `${images}${company}/history/intro.png`
    }
  },
  service: {
    image1: `${images}${service}/serviceImage1.png`,
    image2: `${images}${service}/serviceImage2.png`
  },
  ir: {
    schedule: {
      selectTime: `${images}/local${ir}/selectTime.png`
    }
  },
  esg: {
    esgManagement: {
      intro: `${images}${esg}/esgManagement/intro.png`,
      introMobile: `${images}${esg}/esgManagement/intro_mobile.png`
    },
    environment: {
      intro: `${images}${esg}/environment/intro.png`,
      introMobile: `${images}${esg}/environment/intro_mobile.png`,
      management: `${images}${esg}/environment/management.png`,
      managementMobile: `${images}${esg}/environment/management_mobile.png`,
      playHub: `${images}${esg}/environment/play_hub.png`,
      serverRoom: `${images}${esg}/environment/server_room.png`
    },
    society: {
      intro: `${images}${esg}/society/intro.png`,
      introMobile: `${images}${esg}/society/intro_mobile.png`
    },
    governance: {
      intro: `${images}${esg}/governance/intro.png`,
      introMobile: `${images}${esg}/governance/intro_mobile.png`
    },
    businessConduct: {
      intro: `${images}${esg}/businessConduct/intro.png`,
      introMobile: `${images}${esg}/businessConduct/intro_mobile.png`
    },
    reportPolicy: {
      intro: `${images}${esg}/reportPolicy/intro.png`,
      introMobile: `${images}${esg}/reportPolicy/intro_mobile.png`
    }
  },
  ci: {
    application: {
      image1: `${images}/local/ci/application01.png`,
      image2: `${images}/local/ci/application02.png`,
      image3: `${images}/local/ci/application03.png`,
      image4: `${images}/local/ci/application04.png`,
      image5: `${images}/local/ci/application05.png`,
      image6: `${images}/local/ci/application06.png`
    }
  }
});
export default Images;
