import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const BtnMainLinkClick: FC<Omit<IconProps, 'size'>> = (props) => {
  const { ...rest } = props;
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM16.569 15L13.2911 18.2947C12.9016 18.6862 12.9032 19.3194 13.2947 19.7089C13.6862 20.0984 14.3194 20.0968 14.7089 19.7053L19.7089 14.6797L20.4142 13.9708L19.7053 13.2655L14.7053 8.29108C14.3138 7.90156 13.6806 7.90318 13.2911 8.29471C12.9016 8.68624 12.9032 9.3194 13.2947 9.70892L16.6027 13H8V15H16.569Z"
        fill="white"
      />
    </svg>
  );
};

export default BtnMainLinkClick;
