import React, { forwardRef } from 'react';
import Image, { ImageProps } from 'next/image';
import { helper } from '@/common/utils';
import { useRouter } from 'next/router';
import { useIsMobile } from '@/common/contexts/IsMobileContext';

interface StaticImageProps extends ImageProps {
  src: string;
}

//   try {
//   const url = helper.imagePrefix(res.src);
//   const buffer = await fetch(url).then(async (res) => Buffer.from(await res.arrayBuffer()));
//   blurUrl = await getPlaiceholder(buffer);
// } catch (err) {
//   err;
// }
function StaticImage(
  { src, width, height, className, alt, ...props }: StaticImageProps,
  ref: React.ForwardedRef<HTMLImageElement>
) {
  const isMobile = useIsMobile();
  const router = useRouter();
  const dynamicSizes = router.pathname.includes('/esg') ? '200vw' : '100vw';
  if (!src) return null;
  const imageUrl = src.includes('local') ? helper.imageLocalPrefix(src) : helper.imagePrefix(src);
  return width || height ? (
    <Image
      ref={ref}
      width={width}
      height={height}
      sizes={!isMobile ? dynamicSizes : props.sizes}
      placeholder="empty"
      className={className}
      alt={alt}
      src={imageUrl}
      {...props}
    />
  ) : (
    <Image
      className={className}
      fill
      sizes={props.sizes || '100vw'}
      placeholder="empty"
      src={imageUrl}
      alt={alt}
      {...props}
    />
  );
}

export default forwardRef(StaticImage);
