/* eslint-disable react/button-has-type */
import layout from '@/common/constants/layout';
import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { merge } from '@/styles/tailwind/src';
import type { HTMLProps, ReactNode } from 'react';

interface StylesProps {
  white: string;
  black: string;
}
interface Props extends HTMLProps<HTMLButtonElement> {
  text: string;
  type: 'button' | 'submit' | 'reset' | undefined;
  styleType?: keyof StylesProps;
  IconComponent?: ReactNode;
  buttonIconBetweenClassName?: string;
}
function Button({
  text,
  styleType = 'white',
  disabled,
  IconComponent,
  buttonIconBetweenClassName,
  className,
  type = undefined,
  ...rest
}: Props) {
  const isMobile = useIsMobile();
  const styles: StylesProps = {
    white: 'bg-white text-black border border-grayscale-1 text-grayscale-1',
    black: ' bg-black text-white border border-grayscale-1'
  };
  // const hoverStyle = 'hover:shadow-light-2-2';
  const buttonStyle = isMobile ? 'h-48v' : 'min-h-48';
  const dynamicStyle = text.length <= 3 ? 'w-110' : 'w-auto px-40';
  const IconComponentWithStyle =
    IconComponent && isMobile ? 'w-auto pl-32v pr-26v' : 'w-auto pl-24 pr-18';
  const disabledStyle = 'disabled:border-grayscale-8 disabled:bg-grayscale-8';
  const textStyle = isMobile ? 'text-body12-l-v' : 'text-body10-m';
  const borderStyle = `${isMobile ? 'rounded-8v' : 'rounded-8'} border-solid`;
  return (
    <button
      {...rest}
      type={type || 'button'}
      disabled={disabled}
      className={merge(
        disabledStyle,
        styles[styleType],
        IconComponentWithStyle || '',
        dynamicStyle,
        textStyle,
        buttonStyle,
        borderStyle,
        className
      )}
    >
      <div className={layout.mixins.displayCenter}>
        {text}
        {IconComponent && (
          <>
            <span className={merge('pl-8', buttonIconBetweenClassName)} />
            {IconComponent}
          </>
        )}
      </div>
    </button>
  );
}

export default Button;
