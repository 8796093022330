import { useRef } from 'react';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { helper } from '@/common/utils';
import VideoLayout from '@/components/layout/VideoLayout';
import ScrollFadeInComponent from '@/components/layout/ScrollFadeInLayout';
import { useHelper } from '@/common/hooks/useHelper';
import type { ServicesProps } from '../types';
import MainPageSectionLayout from '../MainPageSectionLayout';
import ServicesSectionTitleLayout from './ServicesSectionTitleLayout';

export default function ServicesSection({ title, subTitle }: ServicesProps) {
  const { getTextWithLineBreaks } = useHelper();
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <MainPageSectionLayout ref={containerRef}>
      <article
        className={useDeviceClasses({
          common: 'flex items-center',
          desktop: 'justify-between',
          mobile: 'flex-col'
        })}
      >
        <ServicesSectionTitleLayout
          title={getTextWithLineBreaks(title)}
          subTitle={getTextWithLineBreaks(subTitle)}
        />
        <ScrollFadeInComponent className="w-auto">
          <VideoLayout
            videoSource={helper.imagePrefix('/videos/main/main.mp4')}
            containerClass={useDeviceClasses({
              mobile: 'mt-30v px-20v',
              common: 'relative'
            })}
            videoClass={useDeviceClasses({
              desktop: 'w-800 h-452 rounded-10',
              mobile: 'w-full min-h-190v rounded-8v mx-auto '
            })}
          />
        </ScrollFadeInComponent>
      </article>
    </MainPageSectionLayout>
  );
}
