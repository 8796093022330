import { useState } from 'react';
import { cloneDeep } from 'lodash';
import route from '@/common/route';
import DesktopView from '@/components/unit/DesktopView';
import MobileView from '@/components/unit/MobileView';
import RightDiagonal from '@/common/icons/RightDiagonal';
import RightArrow2 from '@/common/icons/RightArrow2';
import { usePressRelease } from '@/services/pr/pressRelease.service';
import { twJoin } from 'tailwind-merge';
import layout from '@/common/constants/layout';
import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import useCareers from '@/services/main/careers/careers.service';
import type { NewsProps, PostPreview } from '../types';
import PostList from './desktop/PostList';
import StockInfo from './desktop/StockInfo';
import PostNav from './mobile/PostNav';
import MainPageSectionLayout from '../MainPageSectionLayout';

function NewsSection({ moreLink, posts }: NewsProps) {
  const [randomItems, setRandomItems] = useState<PostPreview[]>([]);
  const [careerRollingItems, setCareerRollingItems] = useState<PostPreview[]>([]);

  const {
    pressReleaseList,
    isLoading: pressApiLoading,
    isError: pressApiError
  } = usePressRelease({ page: 1, size: 9 });
  const { data: careersList, isLoading: careerApiLoading, isError: careerApiError } = useCareers();

  useLayoutEffect(() => {
    if (pressReleaseList.length <= 0) return;
    if (careersList?.result?.length! <= 0) return;

    const pressReleaseListCopy = cloneDeep(pressReleaseList);
    const careerReleaseListCopy = cloneDeep(careersList?.result);

    let currentIndex = 0;
    let careerCurrentIndex = 0;

    function showContents() {
      const carrersUrl = 'https://careers.nhn.com';

      const slicedContents = pressReleaseListCopy.slice(currentIndex, currentIndex + 3);
      slicedContents.forEach((content) => content);

      const slicedCareerContents = careerReleaseListCopy?.slice(
        careerCurrentIndex,
        careerCurrentIndex + 3
      );
      slicedCareerContents?.forEach((content) => content);

      setRandomItems(slicedContents);
      setCareerRollingItems(
        slicedCareerContents?.map((v) => {
          return { title: v.name, link: `${carrersUrl}/recruits/${v.id}?type=list` };
        }) as any
      );

      currentIndex = (currentIndex + 3) % pressReleaseListCopy.length;
      careerCurrentIndex = (careerCurrentIndex + 3) % 6;
    }

    // 초기 실행
    showContents();

    // 주기적으로 실행
    const interval = setInterval(showContents, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [pressReleaseList]);

  // const randomCareerMocks = useMemo(() => {
  //   return getRandomItems(careerMocks, 3);
  // }, []);

  const postRouters: string[] = [route.pr.tabs.pressRelease, route.careers.path, '/'];
  const mobilePostRight = [
    <RightArrow2 key={1} className="h-10v w-14v" />,
    <RightDiagonal key={2} className="h-10v w-14v" />,
    <StockInfo key={3} title="" moreText="" moreLink="" />
  ];
  const mobilePostList = posts.map(({ title }, index) => {
    return {
      title,
      icon: mobilePostRight[index],
      link: postRouters[index],
      newPostCount: index === 1 ? 15 : undefined
    };
  });
  // if (pressReleaseList.length === 0) return <div className="h-280 w-full" />;
  return (
    <MainPageSectionLayout
      desktopClassName={twJoin('w-full flex-row items-center pt-48 pb-168', layout.desktopLayout)}
    >
      <>
        <DesktopView>
          <section className="flex">
            <PostList<PostPreview>
              type="pr"
              title={posts[0].title}
              moreLink={route.pr.tabs.pressRelease}
              moreLinkText={moreLink}
              posts={randomItems}
              isLoading={pressApiLoading || pressApiError}
            />
            {/* * * * * *
             채용공고 API추가되면 isLoading 변경해야함!!
             * * * * * */}
            <PostList<PostPreview>
              type="career"
              title={posts[1].title}
              moreLink={route.careers.path}
              moreLinkText={moreLink}
              posts={careerRollingItems}
              isLoading={careerApiLoading || careerApiError}
            />
            <div className="grow-1 h-176 w-292 shrink-0">
              <StockInfo
                title={posts[2].title}
                moreLink={route.ir.tabs.stock}
                moreText={moreLink}
              />
            </div>
          </section>
        </DesktopView>
        <MobileView>
          <ul className="flex w-full flex-col">
            {mobilePostList.map(({ title, icon, link }, index) => (
              <PostNav key={title} index={index} text={title} href={link} icon={icon} />
            ))}
          </ul>
        </MobileView>
      </>
    </MainPageSectionLayout>
  );
}

export default NewsSection;
